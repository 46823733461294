import { message } from 'antd';
import { FC, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import {
  MainCategoryActionInput,
  MainCategoryBlockEmptyItem,
  MainCategoryBlockHeader,
  MainCategoryBlockItem,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import {
  useCreateCategoryGroup,
  useRemoveCategoryGroup,
  useUpdateCategoryGroupById,
} from '@hooks-api';

import {
  ActiveBlockType,
  ActiveGroupType,
} from '../../../pages/Categories/Categories';
import * as S from './styled';

type MainCategoryBlockType = {
  variant: 'in' | 'out';
  inOutData: Schemas.CategoryGroup[];
  group: 'operational' | 'financial' | 'investments';
  title: string;
  refetchCategories?: () => void;
  isSubItemDragged: boolean;
  handleDragSubItem: (value: boolean) => void;
  setActiveBlock: (item: ActiveBlockType) => void;
  setActiveGroup: (item: ActiveGroupType) => void;
  activeGroup: ActiveGroupType;
};

const MainCategoryBlock: FC<MainCategoryBlockType> = ({
  variant,
  inOutData,
  title,
  group,
  refetchCategories,
  setActiveBlock,
  isSubItemDragged,
  handleDragSubItem,
  setActiveGroup,
  activeGroup,
}) => {
  const isIn = variant === 'in';
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const [updateCategoryGroup] = useUpdateCategoryGroupById();
  const [createCategoryGroup] = useCreateCategoryGroup();
  const [removeCategoryGroup] = useRemoveCategoryGroup();

  const [isGroupAddInputOpen, setIsGroupAddInputOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [action, setAction] = useState('create');
  const [selectedItem, setSelectedItem] =
    useState<Schemas.CategoryGroup | null>(null);

  const [messageApi, contextHolder] = message.useMessage();

  const handleOpenAddGroup = () => {
    setIsGroupAddInputOpen(true);
    setAction('create');
  };

  const handleAddGroup = async () => {
    if (action === 'create') {
      await createCategoryGroup(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            name: inputValue,
            flowType: isIn ? 'money_in' : 'money_out',
            group,
          },
        },
        {
          onSuccess: () => {
            refetchCategories?.();
            messageApi.open({
              type: 'success',
              content: `${translate('categoriesPage.actions.categoryGroup')} ${inputValue} ${translate(
                'categoriesPage.actions.created'
              )}`,
            });
          },
          onFailure: (err) => messageApi.open({
              type: 'error',
              content: err.message,
            }),
        }
      );
    } else if (selectedItem) {
      await updateCategoryGroup(
        {
          parameter: {
            companyId: companyId!,
            id: selectedItem.id,
          },
          requestBody: {
            name: inputValue,
            flowType: selectedItem.flowType,
            group: selectedItem.group,
          },
        },
        {
          onSuccess: () => {
            refetchCategories?.();
            messageApi.open({
              type: 'success',
              content: `${translate('categoriesPage.actions.categoryGroup')} ${inputValue} ${translate(
                'categoriesPage.actions.updated'
              )}`,
            });
          },
          onFailure: (err) => messageApi.open({
              type: 'error',
              content: err.message,
            }),
        }
      );
    }
    setIsGroupAddInputOpen(false);
    setInputValue('');
    setSelectedItem(null);
    refetchCategories?.();
  };

  const handleClearGroupValue = () => {
    setIsGroupAddInputOpen(false);
    setInputValue('');
  };

  const handleEditCategoryGroup = (
    title: string,
    item: Schemas.CategoryGroup
  ) => {
    setAction('edit');
    setInputValue(title);
    setSelectedItem(item);
    setIsGroupAddInputOpen(true);
  };

  const handleRemoveGroup = async (id: string) => {
    await removeCategoryGroup(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
      },
      {
        onSuccess: (res) => {
          if (res) {
            refetchCategories?.();
            messageApi.open({
              type: 'success',
              content: `${translate('categoriesPage.actions.categoryGroup')} ${res.name} ${translate(
                'categoriesPage.actions.removed'
              )}`,
            });
          }
        },
        onFailure: (err) => messageApi.open({
            type: 'error',
            content: err.message,
          }),
      }
    );
    setIsGroupAddInputOpen(false);
  };

  const handleSetActiveBlock = (item: ActiveBlockType) => {
    if (!isSubItemDragged) {
      setActiveBlock(item);
    }
  };

  return (
    <S.Wrapper
      onDragOver={(evt) => evt.preventDefault()}
      onDragEnter={() => handleSetActiveBlock({ title, variant })}
      onDragExit={() => handleSetActiveBlock(null)}
    >
      {contextHolder}
      <MainCategoryBlockHeader isIn={isIn} onPress={handleOpenAddGroup} />
      <S.ListWrapper>
        {inOutData?.length ? (
          inOutData?.map((data) => (
            <MainCategoryBlockItem
              key={data.id}
              item={data}
              variant={variant}
              isSubItemDragged={isSubItemDragged}
              onEditGroup={handleEditCategoryGroup}
              onRemoveGroup={handleRemoveGroup}
              refetchCategories={refetchCategories}
              handleDragSubItem={handleDragSubItem}
              setActiveGroup={setActiveGroup}
              setActiveBlock={setActiveBlock}
              activeGroup={activeGroup}
            />
          ))
        ) : (
          <MainCategoryBlockEmptyItem
            variant={variant}
            title={title}
            group={group}
            refetchCategories={refetchCategories}
            openMessage={(type, content) => messageApi.open({ type, content })}
          />
        )}
      </S.ListWrapper>
      {isGroupAddInputOpen && (
        <MainCategoryActionInput
          isSubItem={false}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSubmit={handleAddGroup}
          handleClose={handleClearGroupValue}
          containerStyles={{ marginTop: 8 }}
        />
      )}
    </S.Wrapper>
  );
};

export default MainCategoryBlock;
