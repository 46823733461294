import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import {
  OnboardingCardHeader,
  OnboardingCheckoutForm,
  OnboardingServicesBlock,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useCreatePaymentIntent } from '@hooks-api';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

import * as S from './styled';

const VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;

const appearance = {
  theme: 'stripe',
};

const OnboardingPaymentDetails: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const stripePromise = loadStripe(VITE_STRIPE_KEY);

  const [paymentIntent, setPaymentIntent] =
    useState<Schemas.PaymentIntent | null>(null);

  const [createPaymentIntent] = useCreatePaymentIntent();

  useEffect(() => {
    createPaymentIntent(
      {
        parameter: {
          companyId: companyId!,
        },
      },
      {
        onSuccess: async (paymentIntent) => {
          setPaymentIntent(paymentIntent);
        },
      }
    );
  }, [companyId, createPaymentIntent]);

  const options = {
    clientSecret: paymentIntent?.clientSecret,
    appearance,
  } as StripeElementsOptions;

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={translate('onboarding.paymentDetails.title')}
        />

        <Row gutter={[24, 0]}>
          <Col span={14}>
            {paymentIntent && (
              <Elements options={options} stripe={stripePromise}>
                <OnboardingCheckoutForm />
              </Elements>
            )}
          </Col>

          <Col span={10}>
            <OnboardingServicesBlock bordered />
          </Col>
        </Row>
      </S.Inner>
    </S.Container>
  );
};

export default OnboardingPaymentDetails;
