import { css } from '@emotion/react';

export const buttonStyles = css`
  .ui-btn.ui-btn {
    --ui-control-height: 48px;
    --ui-control-height-lg: 56px;
    --ui-control-height-sm: 40px;
    --ui-border-radius: 12px;
    --ui-border-radius-lg: 12px;
    --ui-border-radius-sm: 12px;
    --ui-button-padding-inline: 28px;
    --ui-button-padding-inline-lg: 28px;
    --ui-button-padding-inline-sm: 20px;
    --ui-button-content-font-size: 16px;
    --ui-button-content-font-size-sm: 14px;
    --ui-button-content-font-size-lg: 16px;
    --ui-button-font-weight: 600;
    --ui-button-border-color-disabled: transparent;

    --ui-button-default-hover-color: var(--ui-color-text);
    --ui-button-default-hover-border-color: var(--ui-color-text);
    --ui-button-default-active-color: var(--ui-color-text);
    --ui-button-default-active-border-color: var(--ui-color-text);
    --ui-color-primary-hover: var(--Blue-500);
    --ui-color-primary-active: var(--Blue-500);
    --ui-color-text-secondary: var(--ui-color-text);
    --ui-color-text-disabled: var(--Grey-300);
    --ui-color-bg-container-disabled: var(--Grey-100);
    --ui-color-link-hover: var(--ui-color-text);
    --ui-color-link-active: var(--ui-color-text);

    --ui-icon-size: 20px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    & > .ui-btn-icon,
    & > .ui-btn-icon svg {
      height: var(--ui-icon-size);
      width: var(--ui-icon-size);
    }

    &.ui-btn-sm {
      --ui-icon-size: 16px;
    }

    & > .ui-btn-icon svg * {
      vector-effect: non-scaling-stroke;
    }

    &.ui-btn-default {
      border: 2px solid var(--ui-color-primary);
      background-color: transparent;
      color: var(--ui-color-primary);

      &:hover,
      &:active {
        border-color: var(--ui-color-text);
        color: var(--ui-color-text);
      }

      &[disabled] {
        border-color: var(--ui-color-text-disabled);
        color: var(--ui-color-text-disabled);
      }
    }

    &.ui-btn-variant-filled {
      --ui-button-font-weight: 500;
      --ui-button-padding-inline-sm: 12px;

      --ui-icon-size: 20px;

      border: none;
      background-color: var(--ui-color-bg-container-disabled);
      color: var(--Grey-500);

      &&:hover:not(:disabled),
      &&:active:not(:disabled) {
        background-color: var(--ui-color-primary);
        color: var(--White);
      }

      &[disabled] {
        border-color: var(--ui-color-text-disabled);
        color: var(--ui-color-text-disabled);
      }
    }

    &.ui-btn-link {
      --ui-control-height: 32px;
      --ui-control-height-sm: 30px;
      --ui-border-radius: 0;
      --ui-border-radius-sm: 0;
      --ui-button-padding-inline: 4px;
      --ui-button-padding-inline-sm: 4px;

      display: inline-flex;
    }

    &.ui-btn-circle {
      &.ui-btn-sm {
        --ui-icon-size: 20px;
      }

      &.ui-btn-lg {
        --ui-icon-size: 32px;
      }
    }
  }
`;
