import type { ThemeConfig } from 'antd';
import { rgba } from 'emotion-rgba';

import { backgroundColors } from './colors/backgroundColors';
import { baseColors } from './colors/baseColors';
import { colorScheme } from './colors/colorScheme';
import { strokeColors } from './colors/strokeColors';
import { token } from './token';

const isDev = import.meta.env.VITE_APP_MODE === 'DEV';

export const layout = {
  sidebarWidth: 105,
  fixedBarHeight: 76,
};

const textColors = {
  text500: colorScheme.black,
  text400: colorScheme.grey500,
  text200: colorScheme.grey400,
  textBlue400: colorScheme.blue400,
  textLink: baseColors.primary,
  textWarning: baseColors.error,
  textSuccess: baseColors.success,
  textDisabled: colorScheme.grey300,
  devDisabled: '#9F5F9D', // is not present in Figma
};

const iconsColors = {
  iconBlack: colorScheme.black,
  iconLightGrey: baseColors.secondary,
  iconDarkGrey: '#435563',
  iconLineWarning: '#E12020',
  iconLineYellow: '#D48C00',
  iconWarning: '#FFB2B2',
  iconLineSuccess: '#00BB40',
  iconActive: baseColors.primary,
  iconAccent: '#FFFBED',
  iconTag: '#EAEFFF',
  iconTips: '#D0E0FF',
  iconGreenLight: '#00DA4B',
};

const badgeColors = {
  badgeSuccess: '#D3FFDA',
  badgeInfo: '#FFFBED',
  badgeWarning: rgba('#ee0000', 0.04), // is not present in Figma
  badgeGrey: '#F2F5F9',
  badgeBlue: '#EAEFFF',
  badgeBlue100: '#EAF4FC',
};

const buttonColors = {
  buttonBlue: baseColors.primary,
  buttonGrey: '#F0F4F9',
  buttonWhite: colorScheme.white,
};

export const colors = {
  ...colorScheme,
  ...baseColors,
  ...textColors,
  ...backgroundColors,
  ...iconsColors,
  ...strokeColors,
  ...badgeColors,
  ...buttonColors,
};

export const config = {
  cssVar: true,
  token,
  components: {
    Layout: {
      headerBg: isDev ? backgroundColors.bgDev : colors.bgDarkBlue,
      siderBg: isDev ? backgroundColors.bgDev : colors.bgDarkBlue,
      ...(isDev
        ? { siderActiveTab: backgroundColors.bgDevSelected }
        : { siderActiveTab: colors.black }),
      ...(isDev && { siderDisabledTabColor: colors.devDisabled }),
      headerHeight: 72,
      headerPadding: '0 24px',
      headerColor: colors.white,
    },
    Table: {
      headerBg: colors.white,
      headerColor: colors.black,
      headerSplitColor: 'transparent',
      headerBorderRadius: 0,
      rowHoverBg: colors.bgLightBlue200,
      cellPaddingBlock: 8,
    },
    Tag: {
      lineHeightSM: 2.6,
      borderRadiusSM: 20,
      fontSizeSM: 14,
    },
    Input: {
      borderRadiusLG: 6,
      controlHeightLG: 44,
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      inputFontSizeLG: 14,
    },
    InputNumber: {
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      borderRadiusLG: 6,
      fontSizeLG: 14,
    },
    Select: {
      controlHeightLG: 40,
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      borderRadiusLG: 6,
      fontSizeLG: 14,
    },
    DatePicker: {
      colorBorder: colors.strokeGrey,
      colorTextPlaceholder: colors.secondary,
      borderRadius: 6,
      fontSizeLG: 14,
    },
    Breadcrumb: {
      separatorColor: colors.secondary,
      linkColor: colors.secondary,
      lastItemColor: colors.secondary,
      separatorMargin: 2,
    },
    Form: {
      labelColor: colors.textBlue400,
    },
    Segmented: {
      controlHeightLG: 38,
      itemColor: colors.secondary,
      trackBg: colors.bgLightBlue200,
      itemActiveBg: colors.bgLightBlue,
      itemSelectedBg: colors.bgLightBlue,
      itemSelectedColor: colors.bgDarkBlue,
    },
    Modal: {
      colorBgMask: rgba('#001a4d', 0.6), // is not present in Figma
      titleFontSize: 20,
    },
    Tooltip: {
      colorBgSpotlight: colors.bgDarkBlue,
      colorText: colors.white,
      controlHeight: 32,
      fontSize: 12,
    },
    Badge: {
      indicatorHeight: 18,
      textFontSize: 10,
      textFontWeight: 600,
    },
    Drawer: {
      paddingLG: 32,
      colorBgMask: rgba('#001a4d', 0.6), // is not present in Figma
    },
    Alert: {
      borderRadiusLG: 10,
      fontSize: 12,
      colorErrorBorder: colors.bgWarning,
    },
    Switch: {
      handleShadow: 'none',
    },
    Tabs: {
      inkBarColor: colors.primary,
      itemColor: colors.textBlue400,
      horizontalItemPaddingLG: '8px 4px',
      horizontalItemGutter: 24,
      horizontalMargin: '0 0 25px 0',
    },
  },
} satisfies ThemeConfig;
