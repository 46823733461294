import { Flex, Switch } from 'antd';

import { IconPlus } from '@assets';
import { Loader } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import { EmptyRulesList } from './list/EmptyRulesList/EmptyRulesList';
import { Rule } from './list/Rule';
import { useRulesList } from './list/useRulesList';
import { Modal } from './modal/Modal';
import * as S from './styled';
import { useGetRules } from './useGetRules';
import { useLinkedData } from './useLinkedData';

export const Rules = () => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { categories, contacts, projects } = useLinkedData();

  const {
    handleArchiveRule,
    handleRestoreRule,
    isLoading,
    rules,
    refetch,
    toggleArchivedRules,
  } = useRulesList({
    companyId: companyId!,
  });

  const { setStep, modalProps } = useGetRules({ onSuccess: refetch });

  return (
    <Flex gap={32} vertical>
      <Flex justify="space-between">
        <S.Title>{translate('rules.title')}</S.Title>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={12}>
          <Switch onChange={toggleArchivedRules} />
          <S.OnlyArchived>{translate('rules.showOnlyArchived')}</S.OnlyArchived>
        </Flex>
        <Button icon={<IconPlus />} onClick={() => setStep('form')}>
          {translate('rules.addNewRule')}
        </Button>
      </Flex>

      <S.Content vertical>
        {isLoading ? (
          <Loader />
        ) : rules?.length ? (
          <ul>
            {rules.map((item) => (
              <Rule
                key={item.id}
                item={item}
                restoreRule={handleRestoreRule}
                archiveRule={handleArchiveRule}
                projects={projects}
                categories={categories}
                contacts={contacts}
              />
            ))}
          </ul>
        ) : (
          <EmptyRulesList onAddRule={() => setStep('form')} />
        )}
      </S.Content>

      <Modal {...modalProps} />
    </Flex>
  );
};
