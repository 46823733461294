import { App as AppDesign, ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';
import { IoProvider } from 'socket.io-react-hook';

import { AccountProvider } from '@context';
import { ThemeProvider } from '@emotion/react';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';
import { ErrorBoundary } from '@sentry/react';
import { GlobalStyles } from '@ui-kit/theme/GlobalStyles';
import { colors, config } from '@ui-kit/theme/theme';

import AppRouter from './AppRouter';

const queryClient = new QueryClient();

const intercomAppId = import.meta.env.VITE_ROUTE_INTERCOM_APP_ID;
const intercomApiBase = import.meta.env.VITE_ROUTE_INTERCOM_API_BASE;

const App = () => (
  <ErrorBoundary
    fallback={(errorData) => <ErrorFallback {...errorData} fullPage />}
  >
    <QueryClientProvider client={queryClient}>
      <IoProvider>
        <ConfigProvider prefixCls="ui" iconPrefixCls="ui-icon" theme={config}>
          <ThemeProvider theme={{ colors }}>
            <IntercomProvider appId={intercomAppId} apiBase={intercomApiBase}>
              <AppDesign>
                <GlobalStyles />
                <AccountProvider>
                  <DndProvider backend={HTML5Backend}>
                    <AppRouter />
                  </DndProvider>
                </AccountProvider>
              </AppDesign>
            </IntercomProvider>
          </ThemeProvider>
        </ConfigProvider>
      </IoProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
