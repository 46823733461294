import { Flex, Form, Input, InputRef, Modal, ModalProps } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import styled from '@emotion/styled';
import { ModalType } from '@entities/onboarding/OnboardingAddManagersAndUbos/OnboardingAddManagersAndUbos';
import { useTranslate } from '@hooks';
import { colors } from '@theme';

type AddShareholderOrUboModalProps = ModalProps & {
  onSubmit: (data: Schemas.PersonDto) => void;
  entityType?: ModalType;
  initialType?: string;
  initialName?: string;
  isDisabled?: boolean;
};

const AddShareholderModal = styled(Modal)`
  .ui-modal-title {
    font-size: 24px;
  }

  .ui-modal-content {
    button {
      color: ${({ theme }) => theme.colors.text400};
    }
  }
`;

const AddShareholderContainer = styled(Flex)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  padding: 14px 20px 14px 22px;
  margin-bottom: 24px;

  && label {
    font-size: 12px;

    :after {
      content: '';
    }
  }

  && .ui-form-item {
    input {
      width: 308px;
    }
  }
`;

export const AddShareholderOrUboModal: FC<AddShareholderOrUboModalProps> = ({
  onSubmit,
  entityType,
  initialType = '',
  initialName = '',
  isDisabled = false,
  ...props
}) => {
  const { translate, tDynamic } = useTranslate();

  const [newShareholder, setNewShareholder] = useState<{
    type: string;
    name: string;
  }>({
    type: initialType,
    name: initialName,
  });

  useEffect(() => {
    setNewShareholder({ name: initialName, type: initialType });
  }, [initialType, initialName, onSubmit]);

  const addShareholderOrUboSelectOptions = [
    {
      label: tDynamic('onboarding.shareholders.select.labelNatural'),
      value: 'natural',
    },
    {
      label: tDynamic('onboarding.shareholders.select.labelLegal'),
      value: 'legal',
    },
  ];

  const getAdditionalRequestData = () => {
    switch (entityType) {
      case 'ubo': {
        return { isUbo: true };
      }
      case 'manager': {
        return { isDirector: true };
      }
      default:
        return {};
    }
  };

  const addPerson = () => {
    onSubmit({
      type: newShareholder.type,
      name: newShareholder.name,
      ...getAdditionalRequestData(),
    } as any);
  };

  const okButtonProps = {
    ...props.okButtonProps,
    style: {
      color: colors.white,
    },
    disabled: !(newShareholder.name && newShareholder.type),
  };

  const handleChange = (field: 'name' | 'type', value: string) => {
    setNewShareholder((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const inputRef = useRef<InputRef>(null);

  if (inputRef.current) {
    inputRef.current.focus();
  }

  return (
    <AddShareholderModal
      {...props}
      onOk={addPerson}
      okButtonProps={okButtonProps}
      cancelButtonProps={{
        style: {
          color: colors.primary,
        },
      }}
    >
      <AddShareholderContainer align="center" gap={12}>
        <Form.Item label={translate('onboarding.addManagersAndUbos.type')}>
          <Select
            width={155}
            options={addShareholderOrUboSelectOptions}
            placeholder={tDynamic('onboarding.shareholders.select.placeholder')}
            size="large"
            value={newShareholder.type}
            onChange={(value) => handleChange('type', value)}
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item label={translate('onboarding.addManagersAndUbos.name')}>
          <Input
            ref={inputRef}
            placeholder={tDynamic('onboarding.shareholders.namePlaceholder')}
            size="large"
            value={newShareholder.name}
            onChange={(e) => handleChange('name', e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && addPerson()}
          />
        </Form.Item>
      </AddShareholderContainer>
    </AddShareholderModal>
  );
};
