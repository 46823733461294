import { Button, Col, Flex, Form, Input, Row, Typography } from 'antd';
import _, { first } from 'lodash';
import { FC, useState } from 'react';
import { Trans } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconFactory, IconTrash, IconUser } from '@assets';
import { OnboardingCardHeader } from '@entities';
import { AddShareholderOrUboModal } from '@entities/onboarding/OnboardingShareholders/components/AddShareholderOrUboModal/AddShareholderOrUboModal';
import {
  useAccount,
  useFormValidateFields,
  useOnboarding,
  useTranslate,
} from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingShareholders: FC = () => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { form, disabledForm, valuesForm } = useFormValidateFields<{
    people: Schemas.PersonDto[];
  }>();

  const {
    incorporationDetails,
    updateScreen,
    updateIncorporation,
    isIncorporation,
  } = useOnboarding();

  const [update, loading] = useUpdateIncorporation();

  const [isAddShareholderModal, setIsAddShareholderModal] = useState(false);

  const getSumPercentage = () =>
    valuesForm ? _.sum(valuesForm.people?.map((item) => item.shares)) : 0;

  const handleSubmit = (values: Schemas.IncorporationDto) => {
    const companyPerson = first(incorporationDetails?.people);
    if (companyPerson && values.people) {
      update(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: [{
              ...companyPerson,
              people: values.people,
            }],
          },
        },
        {
          onSuccess: (response) => {
            updateIncorporation({ people: response.people });
            updateScreen('UBOS', { active: true });
          },
        }
      );
    }
  };

  const currentPercentage = getSumPercentage();

  const people = first(incorporationDetails?.people)?.people?.filter((person) => person.isShareholder);

  return (
    <S.Container>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        initialValues={{
          people: people,
        }}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={tDynamic(
              `onboarding.shareholders.title_${incorporationDetails?.incorporationType}`
            )}
            description={
              <Row align="bottom" gutter={[20, 0]}>
                <Col span={15}>
                  <Paragraph>
                    <Trans
                      i18nKey={tDynamic(
                        `onboarding.shareholders.description_${incorporationDetails?.companyType || incorporationDetails?.incorporationType}`
                      )}
                      components={[<strong />, <Paragraph />]}
                    />
                  </Paragraph>
                </Col>

                <Col span={9}>
                  <S.ExtraText type="secondary">
                    <Trans
                      i18nKey={tDynamic('onboarding.shareholders.extraText')}
                      components={[<strong />]}
                    />
                  </S.ExtraText>
                </Col>
              </Row>
            }
          />
          <Form.List name="people">
            {(people, { add, remove }) => (
              <>
                <S.FormInner>
                  {people?.length ? (
                    <Row gutter={16}>
                      <Col span={2} />

                      <Col span={16}>
                        <S.FormLabel>
                          {tDynamic('onboarding.shareholders.name')}
                        </S.FormLabel>
                      </Col>

                      <Col span={6}>
                        <S.FormLabel>
                          {tDynamic('onboarding.shareholders.shares')}
                        </S.FormLabel>
                      </Col>
                    </Row>
                  ) : null}

                  {people.length ? (
                    people?.map(({ name, key, ...person }) => (
                      <S.FormItem key={key}>
                        <Form.Item required={false} noStyle>
                          <Row gutter={16} align="middle">
                            <Col span={2} style={{ textAlign: 'center' }}>
                              {
                                form.getFieldsValue()?.people && form.getFieldsValue()?.people[key]?.type === 'legal' && <IconFactory />
                              }
                              {
                                form.getFieldsValue()?.people && form.getFieldsValue()?.people[key]?.type === 'natural' && <IconUser />
                              }
                            </Col>

                            <Col span={16}>
                              <Form.Item
                                {...person}
                                name={[name, 'name']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true }]}
                                noStyle
                              >
                                <Input
                                  placeholder={tDynamic(
                                    'onboarding.shareholders.namePlaceholder'
                                  )}
                                  size="large"
                                />
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Flex align="center" gap={16}>
                                <Form.Item
                                  {...person}
                                  name={[name, 'shares']}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[{ required: true }]}
                                  noStyle
                                >
                                  <S.NumberInput
                                    size="large"
                                    placeholder="%"
                                    suffix="%"
                                  />
                                </Form.Item>

                                {people.length > 1 ? (
                                  <S.ButtonRemove onClick={() => remove(name)}>
                                    <IconTrash />
                                  </S.ButtonRemove>
                                ) : null}
                              </Flex>
                            </Col>
                          </Row>
                        </Form.Item>
                      </S.FormItem>
                    ))
                  ) : (
                    <S.EmptyContainer align="center" justify="center" vertical>
                      <S.EmptyTitle>
                        {translate('onboarding.shareholders.empty_title')}
                      </S.EmptyTitle>
                      <S.EmptyDescription>
                        {translate('onboarding.shareholders.empty_description')}
                      </S.EmptyDescription>
                    </S.EmptyContainer>
                  )}
                </S.FormInner>

                <Flex justify="space-between">
                  <Flex align="center" gap={28}>
                    <S.ButtonAdd onClick={() => setIsAddShareholderModal(true)}>
                      <PlusOutlined />
                      {tDynamic('onboarding.shareholders.addShareholder')}
                    </S.ButtonAdd>

                    <S.Total>
                      {people?.length
                        ? tDynamic(
                            'onboarding.shareholders.totalShareholders',
                            {
                              value: people.length,
                            }
                          )
                        : null}
                    </S.Total>

                    <AddShareholderOrUboModal
                      open={isAddShareholderModal}
                      onCancel={() => setIsAddShareholderModal(false)}
                      title={translate(
                        'onboarding.addManagersAndUbos.addNewShareholder'
                      )}
                      width={572}
                      onSubmit={(data) =>
                        {
                          setIsAddShareholderModal(false);
                          add({
                            type: data.type,
                            name: data.name,
                            isShareholder: true,
                            shares: '',
                            people: [],
                          });
                        }
                      }
                      okText={translate('onboarding.addManagersAndUbos.add')}
                      initialType="natural"
                      isDisabled={isIncorporation && incorporationDetails?.companyType === 'sarl-s'}
                    />
                  </Flex>

                  {currentPercentage > 0 && currentPercentage !== 100 ? (
                    <S.ErrorText>
                      {tDynamic('onboarding.shareholders.sharesPercentage', {
                        value: currentPercentage,
                      })}
                    </S.ErrorText>
                  ) : null}
                </Flex>
              </>
            )}
          </Form.List>
        </S.Inner>

        <Flex justify="flex-end">
          <Button
            type="link"
            onClick={() =>
              updateScreen(
                isIncorporation ? 'COMPANY_TYPE' : 'ACCOUNTING_PLAN',
                { active: true }
              )
            }
          >
            {translate('onboarding.buttonGoBack')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={disabledForm || currentPercentage !== 100}
          >
            {translate('onboarding.buttonNext')}
          </Button>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingShareholders;
