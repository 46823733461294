import { Button, type ButtonProps, Flex, Typography } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

const { Text } = Typography;

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 16px;
  width: 100%;
  min-width: 410px;
  height: 72px;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};

    .connectButton {
      pointer-events: auto;
      display: flex;
    }

    .bankBic {
      display: none;
    }
  }
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 100%;

  ${mediaWidth.laptopM(css`
    font-size: 14px;
  `)};
`;

export const ConnectBlock = styled(Flex)`
  min-width: 96px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ButtonConnect = styled(Button)<ButtonProps>`
  padding: 0;
  font-size: 12px;
  pointer-events: none;
  display: none;

  ${mediaWidth.laptopM(css`
    font-size: 12px;
    height: 36px;
  `)};
`;

export const BankBic = styled(Text)`
  width: 96px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.textDisabled};
`;
