import { Schemas } from '@api-client/generated/types';
import { useAccount, useCountries, useOnboarding, useTranslate } from '@hooks';
import { Flex, Form, Input, Typography, message } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { useUpdateIncorporation } from '@hooks-api';
import { Select } from '@components';

type KYCTaxProps = {
  person: Schemas.Person;
};

const { Paragraph, Title } = Typography;

const KYCTax: FC<PropsWithChildren<KYCTaxProps>> = ({ person }) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const { countries, filterOptionCountries } = useCountries();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const [update] = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.natural.tax')}</Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.naturalDetails.tax')}
        </Paragraph>
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Form.Item
          name="taxResidenceCountryCode"
          label={translate(
            'onboarding.kyc.naturalKycForm.taxResidenceCountryCode.label'
          )}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.taxResidenceCountryCode.placeholder'
            )}
            options={countries}
            size="large"
            onChange={onFinish}
            showSearch
            virtual
            filterOption={filterOptionCountries}
          />
        </Form.Item>

        <Form.Item
          name="taxNumber"
          label={translate('onboarding.kyc.naturalKycForm.taxNumber.label')}
        >
          <Input size="large" onBlur={onFinish} />
        </Form.Item>

        <Form.Item
          name="professionalIncomeCountryCode"
          label={translate(
            'onboarding.kyc.naturalKycForm.professionalIncomeCountryCode.label'
          )}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.professionalIncomeCountryCode.placeholder'
            )}
            options={countries}
            size="large"
            onChange={onFinish}
            showSearch
            virtual
            filterOption={filterOptionCountries}
          />
        </Form.Item>

        <Form.Item
          name="usaLink"
          label={translate('onboarding.kyc.naturalKycForm.usaLink.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.usaLink.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLink.values.yes'
                ),
                value: 'yes',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLink.values.no'
                ),
                value: 'no',
              },
            ]}
            size="large"
            onChange={onFinish}
            virtual
          />
        </Form.Item>

        <Form.Item
          name="usaLinkType"
          label={translate('onboarding.kyc.naturalKycForm.usaLinkType.label')}
        >
          <Select
            placeholder={translate(
              'onboarding.kyc.naturalKycForm.usaLinkType.placeholder'
            )}
            options={[
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.citizen'
                ),
                value: 'citizen',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.greenCard'
                ),
                value: 'greenCard',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.born'
                ),
                value: 'born',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.residentAddress'
                ),
                value: 'residentAddress',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.mailingAddress'
                ),
                value: 'mailingAddress',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.phone'
                ),
                value: 'phone',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.standingOrder'
                ),
                value: 'standingOrder',
              },
              {
                label: translate(
                  'onboarding.kyc.naturalKycForm.usaLinkType.values.proxyHolder'
                ),
                value: 'proxyHolder',
              },
            ]}
            size="large"
            onChange={onFinish}
            disabled={person.kycData?.usaLink !== 'yes'}
            virtual
          />
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default KYCTax;
