import { Flex } from 'antd';
import { FC } from 'react';

import { IconList, IconPlus } from '@assets';
import { useTranslate } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type EmptyRulesListProps = {
  onAddRule: () => void;
};

export const EmptyRulesList: FC<EmptyRulesListProps> = ({ onAddRule }) => {
  const { translate } = useTranslate();

  return (
    <S.EmptyContainer align="center" justify="center" gap={40} vertical>
      <Flex gap={24} align="center" justify="center" vertical>
        <S.Icon justify="center" align="center">
          <IconList />
        </S.Icon>
        <Flex gap={12} align="center" justify="center" vertical>
          <S.EmptyTitle>{translate('rules.empty.title')}</S.EmptyTitle>
          <S.EmptyDescription>
            {translate('rules.empty.description')}
          </S.EmptyDescription>
        </Flex>
      </Flex>

      <Button icon={<IconPlus />} onClick={onAddRule}>
        {translate('rules.addRule')}
      </Button>
    </S.EmptyContainer>
  );
};
