import type { FC } from 'react';

import { Global } from '@emotion/react';

import { buttonStyles } from '../Button/buttonStyles';
import { antdStyles } from './antdStyles';
import { resetStyles } from './resetStyles';
import './theme.css';

export const GlobalStyles: FC = () => (
  <Global styles={[resetStyles, antdStyles, buttonStyles]} />
);
