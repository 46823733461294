import { Col, Flex } from 'antd';
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconBank } from '@assets';
import {
  AccountAddModal,
  AccountConnectionErrorModal,
  AccountDisconnectModal,
  AccountsList,
  BankConnectModal,
  BanksList,
  ImportConfirmModal,
  PageMeta,
} from '@entities';
import { useAccount, useAccounts, useTranslate } from '@hooks';
import { useGetAccountsList } from '@hooks-api';

import * as S from './styled';

type Account = Schemas.Account;

const Accounts: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const [searchParams] = useSearchParams();

  const { accounts: accountsList, initialAccounts } = useAccounts();

  const { loading: accountsLoading } = useGetAccountsList({
    params: {
      companyId: companyId!,
    },
    config: {
      onSuccess(accounts) {
        initialAccounts(accounts);
      },
    },
  });

  const [hasError, setHasError] = useState(
    searchParams.get('error') === 'true'
  );
  const [isAccountAddOpen, setIsAccountAddOpen] = useState(false);
  const [selectedAccountEdit, setSelectedAccountEdit] =
    useState<Account | null>(null);
  const [selectedAccountDisconnect, setSelectedAccountDisconnect] =
    useState<Account | null>(null);
  const [selectedBankConnect, setSelectedBankConnect] =
    useState<Schemas.Bank | null>(null);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [term, setTerm] = useState('');

  const handleSearch = (value: string) => {
    setTerm(value);
  };

  const handleEditAccount = (account: Account | null) => {
    setIsAccountAddOpen(true);
    setSelectedAccountEdit(account);
  };

  const handleDisconnectAccount = (account: Account) => {
    setSelectedAccountDisconnect(account);
  };

  const handleConnectBank = (bank: Schemas.Bank) => {
    setSelectedBankConnect(bank);
  };

  const handleCancelAccount = () => {
    setIsAccountAddOpen(false);
    setSelectedAccountEdit(null);
  };

  const handleCancelDisconnectAccount = () => {
    setSelectedAccountDisconnect(null);
  };

  const handleCancelConnectBank = () => {
    setSelectedBankConnect(null);
  };

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translate('accounts.title')} />

      <S.Heading level={2}>{translate('accounts.title')}</S.Heading>

      <Flex gap={40}>
        <S.AccountsWrapper>
          <Flex gap={12} vertical>
            <S.SubHeading level={4}>
              {translate('accounts.titleSection')}
            </S.SubHeading>

            <AccountsList
              accounts={accountsList}
              loading={accountsLoading}
              onEdit={handleEditAccount}
              onDisconnect={handleDisconnectAccount}
              onImportModalOpen={setImportModalOpen}
              onAccountIdChange={(id) => setAccountId(id)}
            />
          </Flex>
        </S.AccountsWrapper>

        <S.BanksWrapper>
          <S.HeaderRow gutter={[16, 0]}>
            <Col span={12}>
              <Flex gap={6} vertical>
                <Flex gap={6} vertical>
                  <S.HeaderTitle>
                    {translate('accounts.header.title')}
                  </S.HeaderTitle>
                  <span>{translate('accounts.header.description')}</span>
                </Flex>
                <S.Search onChange={(e) => handleSearch(e.target.value)} />
              </Flex>
            </Col>

            <Col span={12}>
              <Flex justify="flex-end">
                <S.AddBank gap={16}>
                  <S.BankIconWrapper >
                    <IconBank height={24} width={24} />
                  </S.BankIconWrapper>

                  <Flex gap={7} vertical>
                    <S.FindBankQuestion>
                      {translate('accounts.header.cantFindYourBank')}
                    </S.FindBankQuestion>
                    <S.AddBankLink onClick={() => setIsAccountAddOpen(true)}>
                      {translate('accounts.header.addBank')}
                    </S.AddBankLink>
                  </Flex>
                </S.AddBank>
              </Flex>
            </Col>
          </S.HeaderRow>

          <BanksList term={term} onBankConnect={handleConnectBank} />
        </S.BanksWrapper>
      </Flex>

      <AccountAddModal
        account={selectedAccountEdit}
        open={isAccountAddOpen}
        onCancel={handleCancelAccount}
      />

      <AccountDisconnectModal
        account={selectedAccountDisconnect}
        onCancel={handleCancelDisconnectAccount}
      />

      <AccountConnectionErrorModal open={hasError} onCancel={() => setHasError(false)} />

      <BankConnectModal
        bank={selectedBankConnect}
        onCancel={handleCancelConnectBank}
      />

      <ImportConfirmModal
        accountId={accountId}
        open={importModalOpen}
        setOpen={setImportModalOpen}
      />
    </Flex>
  );
};

export default Accounts;
