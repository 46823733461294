import { Button, Form, Input } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { useTranslate } from '@hooks';

import * as S from './styled';

type LoginFormProps = {
  isLoading?: boolean;
  error?: string;
  onError?: (error: string) => void;
  onSubmit: (values: Schemas.LoginDto) => void;
};

const LoginForm: FC<LoginFormProps> = ({
  isLoading,
  error,
  onSubmit,
  onError,
}) => {
  const { translate } = useTranslate();

  return (
    <Form
      onFinish={onSubmit}
      onValuesChange={() => onError?.('')}
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        label={translate('auth.login.email.label')}
        name="email"
        rules={[
          { required: true, message: translate('auth.login.email.error') },
        ]}
      >
        <Input
          placeholder={translate('auth.login.email.placeholder')}
          size="large"
        />
      </Form.Item>

      <Form.Item
        label={translate('auth.login.password.label')}
        name="password"
        rules={[
          {
            required: true,
            message: translate('auth.login.password.error'),
          },
        ]}
        style={{ marginBottom: '16px' }}
      >
        <Input.Password
          placeholder={translate('auth.login.password.placeholder')}
          size="large"
        />
      </Form.Item>

      {error && <S.Error>{translate('auth.login.invalidCredentials')}</S.Error>}

      <S.ForgotPassword to="/forgot-password">
        {translate('auth.login.forgotPassword')}
      </S.ForgotPassword>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
          block
        >
          {translate('auth.login.buttonLogin')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
