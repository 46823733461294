import { Modal as BaseModal, Flex } from 'antd';
import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

const Description = styled.span`
  font-size: 16px;
  line-height: 24px;
`;

export const Modal = ({
  title,
  description,
  children,
  ...modalProps
}: Parameters<typeof BaseModal>[0] &
  PropsWithChildren<{ title: React.ReactNode; description?: string }>) => (
  <BaseModal width={904} {...modalProps}>
    <Flex gap={20} vertical>
      <Flex gap={9} vertical>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Flex>

      {children}
    </Flex>
  </BaseModal>
);
