import { Flex, type FlexProps, Tabs, Typography } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Title = styled(Typography.Title)<{ isincome: string }>`
  width: max-content;
  font-size: 36px;
  border-bottom: 8px solid
    ${({ theme, isincome }) =>
      isincome ? theme.colors.success : theme.colors.error};
`;

export const TabsInvoices = styled(Tabs)`
  && {
    .ui-tabs-nav {
      margin-bottom: 8px;
    }
  }
`;

export const WrapperLeft = styled.div`
  width: 340px;
`;

export const WrapperRight = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  display: flex;
  gap: 30px;
  padding: 30px;
  flex: 1;
`;

export const RecognitionWrapper = styled(Flex)`
  width: 342px;
  height: 100%;
`;

export const RecognitionTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const RecognitionDescription = styled.div`
  padding: 0 25px;
  width: 212px;
  height: 40px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const FormWrapper = styled.div`
  max-width: 440px;
  flex: 1;
`;

export const FormHeader = styled(Flex)`
  margin-bottom: 8px;
`;

export const TitleDetails = styled(Typography.Title)`
  &.ui-typography {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Content = styled(Flex)<FlexProps & { hidden: boolean }>`
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`;

export const Loader = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
