import {
  DatePicker,
  Flex,
  Form,
  Modal,
  ModalProps,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import { useInitializeBankConnections } from '@hooks/api';

import * as S from './styled';

const { Title, Text } = Typography;

type BankConnectModalProps = {
  bank?: Schemas.Bank | null;
  onCancel: () => void;
} & ModalProps;

const BankConnectModal = ({
  bank,
  onCancel,
  ...rest
}: BankConnectModalProps) => {
  const open = Boolean(bank);
  const { companyId } = useAccount();

  const [bankConnect, loading] = useInitializeBankConnections();

  const minDate = dayjs(bank?.earliestDateForTransactions);
  const maxDate = dayjs().subtract(1, 'day');

  const [form] = Form.useForm();

  const handleSubmit = (data: { date: dayjs.Dayjs }) => {
    if (!bank) {
      return;
    }

    bankConnect(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          bankId: bank.id,
          dateForTransactions: data.date.toISOString(),
        },
      },
      {
        onSuccess: (response) => {
          window.open(response.url!, '_self');
        },
      }
    );
  };

  return (
    <Modal
      title={<Title level={3}>{t('accounts.bankConnect.title')()}</Title>}
      open={open}
      width={528}
      footer={null}
      centered
      {...rest}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          date: minDate,
        }}
        onFinish={handleSubmit}
      >
        <Flex gap={24} vertical>
          <Text>
            <Trans components={[<Text strong />]}>
              {t('accounts.bankConnect.description', {
                name: bank?.name,
              })()}
            </Trans>
          </Text>
          <S.Fieldset gap={12} vertical>
            <Form.Item
              name="date"
              label={<Text>Import starting from</Text>}
              noStyle
            >
              <DatePicker
                allowClear={false}
                minDate={minDate}
                maxDate={maxDate}
                placeholder={minDate.format('DD.MM.YYYY')}
                format="DD.MM.YYYY"
                size="large"
              />
            </Form.Item>
            <S.Warning>
              <Text>
                {t('accounts.bankConnect.warning', {
                  date: minDate.format('DD.MM.YYYY'),
                })()}
              </Text>
            </S.Warning>
          </S.Fieldset>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <S.Button onClick={onCancel}>Cancel</S.Button>
              <S.Button loading={loading} type="primary" htmlType="submit">
                Connect
              </S.Button>
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};

export default BankConnectModal;
