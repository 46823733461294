import { Layout } from 'antd';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import styled from '@emotion/styled';
import { useAccount } from '@hooks';
import { useGetUserData } from '@hooks/api';
import { layout } from '@theme';

import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';

const Container = styled(Layout)`
  min-height: 100vh;
  margin-left: ${`${layout.sidebarWidth}px`};
  padding-top: 72px;
`;

const Content = styled(Layout.Content, {
  shouldForwardProp: (prop) => prop !== 'noContentPadding',
})<{ noContentPadding?: boolean }>`
  padding: ${({ noContentPadding }) => (noContentPadding ? 0 : '50px')};
`;

export const LayoutProtected = ({
  noContentPadding,
}: {
  noContentPadding?: boolean;
}) => {
  const navigate = useNavigate();

  const { isLogged, account, setAccount, company, setUserAccess } =
    useAccount();
  const { user, loading } = useGetUserData();

  const { boot } = useIntercom();

  useEffect(() => {
    if (user && !account) {
      setAccount(user);
    }

    if (user) {
      setUserAccess(user.companies[0]?.userAccessRights);
    }

    if (account) {
      if (account.intercomHash) {
        boot({
          email: account.email,
          name: account.fullName,
          userId: account.id,
          userHash: account.intercomHash,
        });
      }
    }
  }, [user, account, setAccount, setUserAccess, boot]);

  useEffect(() => {
    if (!isLogged) {
      navigate('/login');
    } else {
      if (company?.status === 'incorporating') {
        navigate('/start');
      }
    }
  }, [company?.status, isLogged, navigate]);

  if (!user || loading) {
    return null;
  }

  return (
    <Layout>
      <Header />

      <Container>
        <Sidebar />

        <Content noContentPadding={noContentPadding}>
          <Outlet />
        </Content>
      </Container>
    </Layout>
  );
};
