import { Control, SelectOptions, operator } from './utils';

export const getDocumentsConfig = ({
  contacts,
}: {
  contacts: SelectOptions;
}): {
  if: Control<'contact_name', 'attribute', 'operator' | 'value'>;
  then: Control<'assign_contact', 'name', 'value'>;
} => ({
    if: {
      selector: {
        type: 'select',
        props: {
          placeholder: 'rules.modal.placeholders.attribute',
          width: 206,
          disabled: true,
          options: [
            {
              label: 'rules.modal.attributes.contactName',
              value: 'contact_name',
            },
          ],
        },
        fieldName: 'attribute',
      },
      operator: {
        contact_name: operator,
      },
    },
    then: {
      selector: {
        type: 'select',
        uniqueValue: true,
        props: {
          placeholder: 'rules.modal.placeholders.action',
          width: 345,
          options: [
            {
              label: 'rules.modal.actions.contact',
              value: 'assign_contact',
            },
          ],
        },
        fieldName: 'name',
      },
      operator: {
        assign_contact: [
          {
            type: 'select',
            props: {
              placeholder: 'rules.modal.placeholders.action',
              width: 411,
              showSearch: true,
              options: contacts,
              optionFilterProp: 'label',
            },
            fieldName: 'value',
          },
        ],
      },
    },
  });
