import type { TableColumnsType } from 'antd';
import { Tooltip } from 'antd';
import dayjs, { type Dayjs, type QUnitType, type UnitTypeLong } from 'dayjs';
import _ from 'lodash';
import { MouseEvent, ReactNode } from 'react';
import { type NavigateFunction } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconInfo } from '@assets';
import { Amount } from '@entities';
import { LocalePaths } from '@locales';
import { getDateRangeByInterval, setSearchParams } from '@utils';

import * as S from './styled';

export type ReportRow = Schemas.CategoryReportRow;

type CategoryGroup = Schemas.CategoryGroup;
type Category = Schemas.Category;
type Group = Schemas.CreateCategoryGroupDto;

type Unit = UnitTypeLong | QUnitType;

type ReportRowWithFlowType = ReportRow & { flowType: string };

type DatePeriods = {
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
};

type CategoryGroupType = {
  money_in?: CategoryGroup[];
  money_out?: CategoryGroup[];
};

type CategoryGroups = Record<Group['group'], CategoryGroupType>;

type DatePeriodsWithUnit = {
  unit: Unit;
} & DatePeriods;

type DataSource = {
  data: Record<string, ReportRowWithFlowType[]>;
  categoryIn: ReportRow[];
  categoryOut: ReportRow[];
  categoryGroups: CategoryGroups;
  showByPlDate: string;
} & DatePeriodsWithUnit;

type TableData = {
  key: string;
  total: ReactNode;
  group: ReactNode;
  isCategory?: boolean;
  children?: TableData[];
};

type DataSourceInterval = {
  title: string;
  dataIndex: string;
  key: string;
  reportDate: number | Dayjs | Date;
};

type DataSourceCell = {
  data: ReportRowWithFlowType[];
  column: DataSourceInterval;
  flowType: string;
};

type GroupByName = {
  categoryIn: ReportRow[];
  categoryOut: ReportRow[];
  defaultName?: string;
  unit: Unit;
  key?: string;
};

type RedirectToTransactions = {
  dateFrom: number | Dayjs | Date | null;
  dateTo: number | Dayjs | Date | null;
  categoryIds?: string[];
  showByPlDate: string;
};

const COLUMN_GROUP_WIDTH = 280;
const COLUMN_AMOUNT_WIDTH = 135;

const getDataIndexByUnit = (
  reportDate: Dayjs | Date | number | string,
  unit: Unit
) => {
  const date = dayjs(reportDate);

  return unit === 'quarter'
    ? `Q${date.quarter()}_${date.format('YY')}`
    : date.format('MMM_YY');
};

const redirectToTransactions = (
  e: MouseEvent,
  { dateFrom, dateTo, ...params }: RedirectToTransactions,
  navigate: NavigateFunction
) => {
  e.stopPropagation();

  navigate(
    `/transactions?${setSearchParams({
      ...params,
      dateFrom: dayjs(dateFrom).format('YYYY-MM-DD'),
      dateTo: dayjs(dateTo).format('YYYY-MM-DD'),
      showByPlDate: params?.showByPlDate === 'pl' ? 'true' : 'false',
    })}`
  );
};

export const statsGroupByKey = ({
  categoryIn,
  categoryOut,
  defaultName = '',
  unit,
  key = 'groupName',
}: GroupByName) => {
  const data = [
    ...categoryIn.map((item) => ({ ...item, flowType: 'money_in' })),
    ...categoryOut.map((item) => ({ ...item, flowType: 'money_out' })),
  ];

  return _.groupBy(
    data
      .filter((item) => item.groupName)
      .map((item) => ({
        ...item,
        reportDate: getDataIndexByUnit(item.reportDate, unit),
        categoryName: item.categoryName || defaultName,
      })),
    key
  );
};

export const getTableWidth = ({
  dateFrom,
  dateTo,
  unit,
}: DatePeriodsWithUnit): number => {
  const countColumns = getDateRangeByInterval(dateFrom, dateTo, unit).length;

  return countColumns * COLUMN_AMOUNT_WIDTH + COLUMN_GROUP_WIDTH;
};

const getDatesByInterval = ({
  dateFrom,
  dateTo,
  unit,
}: DatePeriodsWithUnit): DataSourceInterval[] => {
  const intervalDates = getDateRangeByInterval(dateFrom, dateTo, unit);

  return intervalDates.map((item) => {
    const isQuarter = unit === 'quarter';
    const date = dayjs(item);

    const dataIndex = getDataIndexByUnit(item, unit);

    return {
      key: dataIndex,
      title: isQuarter
        ? `Q${date.quarter()} ${date.format('YY')}`
        : `${date.format('MMM YY')}`,
      width: COLUMN_AMOUNT_WIDTH,
      reportDate: item,
      align: 'right',
      dataIndex,
    };
  });
};

const getTotalAmount = (data: ReportRow[]) =>
  _.sum(data.filter((item) => item.categoryId).map((item) => item.sum)) || '-';

const getGroupCellSumAmount = (data: ReportRow[], column: DataSourceInterval) =>
  (data &&
    _.sum(
      data
        .filter((dataItem) => column.dataIndex === dataItem.reportDate)
        .map((item) => item.sum)
    )) ||
  '-';

const getSubGroupCellSumAmount = ({ data, column, flowType }: DataSourceCell) =>
  (data &&
    _.sum(
      data
        .filter(
          (dataItem) =>
            column.dataIndex === dataItem.reportDate &&
            dataItem.flowType === flowType
        )
        .map((item) => item.sum)
    )) ||
  '-';

const getCategoryGroupCellSumAmount = ({
  data,
  column,
  flowType,
  subCategory,
}: DataSourceCell & { subCategory: CategoryGroup }) =>
  (data &&
    _.sum(
      data
        .filter(
          (dataItem) =>
            column.dataIndex === dataItem.reportDate &&
            dataItem.categoryGroupName === subCategory.name &&
            dataItem.flowType === flowType
        )
        .map((item) => item.sum)
    )) ||
  '-';

const getCategoryCellSumAmount = ({
  data,
  column,
  flowType,
  category,
}: DataSourceCell & { category: Category }) =>
  (data &&
    _.sum(
      data
        .filter(
          (dataItem) =>
            column.dataIndex === dataItem.reportDate &&
            dataItem.categoryName === category.name &&
            dataItem.flowType === flowType
        )
        .map((item) => item.sum)
    )) ||
  '-';

const getGroupCellTotal = (data: ReportRow[]) =>
  (data && _.sum(data.map((item) => item.sum))) || '-';

const getSubGroupCellTotal = (
  data: ReportRowWithFlowType[],
  flowType: string
) =>
  (data &&
    _.sum(
      data.filter((item) => item.flowType === flowType).map((item) => item.sum)
    )) ||
  '-';

const getCategoryGroupCellTotal = (
  data: ReportRowWithFlowType[],
  name: string
) =>
  (data &&
    _.sum(
      data
        .filter((dataItem) => dataItem.categoryGroupName === name)
        .map((item) => item.sum)
    )) ||
  '-';

const getCategoryCellTotal = (data: ReportRowWithFlowType[], name: string) =>
  (data &&
    _.sum(
      data
        .filter((dataItem) => dataItem.categoryName === name)
        .map((category) => category.sum)
    )) ||
  '-';

const getGeneralTotalCell = (data: ReportRowWithFlowType[]) =>
  (data && _.sum(data.map((item) => item.sum))) || '-';

const getNextDate = (
  item: DataSourceInterval,
  columnsByDates: DataSourceInterval[]
) => {
  const itemDateIdx = columnsByDates.findIndex((el) => el.key === item.key);
  if (itemDateIdx < columnsByDates.length - 1) {
    return columnsByDates[itemDateIdx + 1].reportDate;
  }

  return null;
};

export const createColumns = (
  { dateFrom, dateTo, unit }: DatePeriodsWithUnit,
  translate: (key: LocalePaths) => string
): TableColumnsType<TableData> => {
  const columnsByDates = getDatesByInterval({ dateFrom, dateTo, unit });

  return [
    {
      key: 'group',
      dataIndex: 'group',
      width: COLUMN_GROUP_WIDTH,
      fixed: 'left',
    },
    ...columnsByDates,
    {
      title: translate('report.table.total'),
      key: 'total',
      dataIndex: 'total',
      width: COLUMN_AMOUNT_WIDTH,
      align: 'right',
      fixed: 'right',
    },
  ];
};

export const createDataSource = (
  {
    data,
    dateFrom,
    dateTo,
    unit,
    categoryGroups,
    categoryIn,
    categoryOut,
    showByPlDate,
  }: DataSource,
  translate: (key: LocalePaths) => string,
  navigate: NavigateFunction
): TableData[] => {
  const columnsByDates = getDatesByInterval({ dateFrom, dateTo, unit });

  const groupedDataByReportDate = statsGroupByKey({
    key: 'reportDate',
    unit,
    categoryIn,
    categoryOut,
  });

  return [
    ...(Object.keys(categoryGroups) as Group['group'][])
      .map((parentKey) => {
        const parent = data[parentKey];
        const subCategory = categoryGroups[parentKey];
        return [
          {
            key: parentKey,
            ..._.reduce(
              columnsByDates,
              (months: Record<string, ReactNode>, item) => {
                months[item.key] = (
                  <>
                    {parentKey !== 'operational' && <S.Block />}
                    {parentKey !== 'operational' && <S.Block />}
                    <S.GroupCell>
                      <Amount
                        amount={getGroupCellSumAmount(parent, item)}
                        onClick={(e) =>
                          redirectToTransactions(
                            e,
                            {
                              categoryIds: Object.values(subCategory)
                                .flat()
                                .map((item) => item.categories)
                                .flat()
                                .map((item) => item.id),
                              dateFrom: item.reportDate,
                              dateTo:
                                getNextDate(item, columnsByDates) || dateTo,
                              showByPlDate,
                            },
                            navigate
                          )
                        }
                      />
                    </S.GroupCell>
                  </>
                );

                return months;
              },
              {}
            ),
            total: (
              <>
                {parentKey !== 'operational' && <S.Block />}
                {parentKey !== 'operational' && <S.Block />}
                <S.TotalCell>
                  <Amount amount={getGroupCellTotal(parent)} />
                </S.TotalCell>
              </>
            ),
            group: (
              <>
                {parentKey !== 'operational' && <S.Block />}
                {parentKey !== 'operational' && <S.Block />}
                <S.GroupName>
                  {translate(
                    `report.table.parents.${parentKey}` as LocalePaths
                  )}

                  <Tooltip
                    title={translate(
                      `report.table.parents.${parentKey}` as LocalePaths
                    )}
                  >
                    <IconInfo />
                  </Tooltip>
                </S.GroupName>
              </>
            ),
          },
          ...(Object.keys(subCategory) as (keyof typeof subCategory)[]).map(
            (key) => ({
              key: `${parentKey}-${key}`,
              ..._.reduce(
                columnsByDates,
                (months: Record<string, ReactNode>, item) => {
                  months[item.key] = (
                    <S.Cell>
                      <Amount
                        amount={getSubGroupCellSumAmount({
                          data: parent,
                          column: item,
                          flowType: key,
                        })}
                        onClick={(e) =>
                          redirectToTransactions(
                            e,
                            {
                              categoryIds: (subCategory[key] || [])
                                .map((item: CategoryGroup) => item.categories)
                                .flat()
                                .map((item: Category) => item.id),
                              dateFrom: item.reportDate,
                              dateTo:
                                getNextDate(item, columnsByDates) || dateTo,
                              showByPlDate,
                            },
                            navigate
                          )
                        }
                      />
                    </S.Cell>
                  );

                  return months;
                },
                {}
              ),
              group: (
                <S.SubGroupName>
                  {translate(`report.table.${key}` as LocalePaths)}
                </S.SubGroupName>
              ),
              total: (
                <S.TotalCell>
                  <Amount amount={getSubGroupCellTotal(parent, key)} />
                </S.TotalCell>
              ),
              children: (subCategory as any)[key].map(
                (subItem: CategoryGroup) => ({
                  key: subItem.id,
                  ..._.reduce(
                    columnsByDates,
                    (months: Record<string, ReactNode>, item) => {
                      months[item.key] = (
                        <S.Cell>
                          <Amount
                            amount={getCategoryGroupCellSumAmount({
                              data: parent,
                              column: item,
                              subCategory: subItem,
                              flowType: key,
                            })}
                            onClick={(e) =>
                              redirectToTransactions(
                                e,
                                {
                                  categoryIds: subItem.categories.map(
                                    (category) => category.id
                                  ),
                                  dateFrom: item.reportDate,
                                  dateTo:
                                    getNextDate(item, columnsByDates) || dateTo,
                                  showByPlDate,
                                },
                                navigate
                              )
                            }
                          />
                        </S.Cell>
                      );

                      return months;
                    },
                    {}
                  ),
                  total: (
                    <S.TotalCell>
                      <Amount
                        amount={getCategoryGroupCellTotal(parent, subItem.name)}
                      />
                    </S.TotalCell>
                  ),
                  group: <S.ChildGroupName>{subItem.name}</S.ChildGroupName>,
                  children: subItem.categories.map((category: Category) => ({
                    key: category.id,
                    isCategory: true,
                    ..._.reduce(
                      columnsByDates,
                      (months: Record<string, ReactNode>, item) => {
                        months[item.key] = (
                          <S.Cell>
                            <Amount
                              amount={getCategoryCellSumAmount({
                                data: parent,
                                column: item,
                                flowType: key,
                                category,
                              })}
                              onClick={(e) =>
                                redirectToTransactions(
                                  e,
                                  {
                                    categoryIds: [category.id],
                                    dateFrom: item.reportDate,
                                    dateTo:
                                      getNextDate(item, columnsByDates) ||
                                      dateTo,
                                    showByPlDate,
                                  },
                                  navigate
                                )
                              }
                            />
                          </S.Cell>
                        );

                        return months;
                      },
                      {}
                    ),
                    total: (
                      <S.TotalCell>
                        <Amount
                          amount={getCategoryCellTotal(parent, category.name)}
                        />
                      </S.TotalCell>
                    ),
                    group: <S.CategoryName>{category.name}</S.CategoryName>,
                  })),
                })
              ),
            })
          ),
        ];
      })
      .flat(),
    {
      key: 'table-total',
      ..._.reduce(
        columnsByDates,
        (months: Record<string, ReactNode>, item) => {
          months[item.key] = (
            <>
              <S.Block />
              <S.TotalCell>
                {groupedDataByReportDate[item.key] && (
                  <Amount
                    amount={getGeneralTotalCell(
                      groupedDataByReportDate[item.key]
                    )}
                  />
                )}
              </S.TotalCell>
              <S.Block />
            </>
          );

          return months;
        },
        {}
      ),
      total: (
        <>
          <S.Block />
          <S.TotalCell>
            <Amount amount={getTotalAmount([...categoryIn, ...categoryOut])} />
          </S.TotalCell>
          <S.Block />
        </>
      ),
      group: (
        <>
          <S.Block />
          <S.SubGroupName isTotal>
            {translate('report.table.total')}
          </S.SubGroupName>
          <S.Block />
        </>
      ),
    },
  ];
};
