import { Button as BaseButton } from 'antd';

import { css } from '@emotion/react';

const styles = css`
  && {
    --ui-icon-size: 16px;
    --ui-button-content-font-size: 12px;
    --ui-border-radius: 8px;
    --ui-button-padding-inline: 16px;
    --ui-control-height: 32px;

    &.ui-btn-link {
      --ui-control-height: 28px;
    }
  }
`;

type ButtonProps = Omit<Parameters<typeof BaseButton>[0], 'size' | 'type'> & {
  size?: Parameters<typeof BaseButton>[0]['size'] | 'xs';
  type?: 'link' | 'default' | 'primary' | 'dashed';
};

export const Button = ({ size, ...props }: ButtonProps) =>
  size === 'xs' ? (
    <BaseButton {...props} css={styles} />
  ) : (
    <BaseButton {...props} size={size} />
  );
