import { Flex } from 'antd';
import { FC } from 'react';

import { Loader } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { useGetIncorporationInvoice } from '@hooks-api';
import { getCurrencySymbol } from '@utils';

import * as S from './styled';

type OnboardingServicesBlockProps = {
  bordered?: boolean;
};

const OnboardingServicesBlock: FC<OnboardingServicesBlockProps> = ({
  bordered,
}) => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { invoice, loading } = useGetIncorporationInvoice({
    params: {
      companyId: companyId!,
    },
  });

  if (!invoice || loading) {
    return <Loader />;
  }

  return (
    <S.IncorporationServices bordered={bordered}>
      <S.IncorporationHeader>
        {translate('onboarding.services.incorporation.services')}
      </S.IncorporationHeader>

      <S.List gap={15} vertical>
        {invoice.items.map((item) => (
          <Flex key={item.description} justify="space-between">
            {tDynamic(`onboarding.services.incorporation.${item.name}`)}

            <S.Amount>
              {getCurrencySymbol('EUR')}
              {item.totalPrice}
            </S.Amount>
          </Flex>
        ))}
      </S.List>

      <Flex justify="space-between">
        <S.IncorporationFooterText>
          {translate('onboarding.services.incorporation.total')}
        </S.IncorporationFooterText>

        <S.IncorporationFooterText>
          {getCurrencySymbol('EUR')}
          {invoice.total}
        </S.IncorporationFooterText>
      </Flex>
    </S.IncorporationServices>
  );
};

export default OnboardingServicesBlock;
