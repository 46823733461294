import { Button, Col, Flex, Form, Row, Switch, Typography } from 'antd';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import styled from '@emotion/styled';
import { OnboardingCardHeader } from '@entities';
import { AddShareholderOrUboModal } from '@entities/onboarding/OnboardingShareholders/components/AddShareholderOrUboModal/AddShareholderOrUboModal';
import { useAccount, useOnboarding } from '@hooks';
import { useUpdateIncorporation } from '@hooks/api';

import { ListItem } from './ListItem';
import { hasDirectorAndAuthorized } from './hasDirectorAndAuthorized';
import { isAnyLegalPersonWithoutChildren } from './legalPersonWithoutChildren';

const { Paragraph } = Typography;

const Container = styled(Flex)`
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  height: 390px;
  background-color: ${({ theme }) => theme.colors.badgeGrey};
  border-radius: 8px;
`;

const ActionButton = styled(Button)`
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
`;

const ItemsContainer = styled(Flex)`
  width: 300px;
  min-width: 300px;
  padding-right: 20px;
  margin-bottom: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.strokeGrey};
    border-radius: 4px;
  }
`;

const SettingsContainer = styled(Flex)`
  width: 300px;
  min-width: 300px;
  padding-right: 20px;
  margin-bottom: 20px;
  flex-grow: 1;
`;

const Actions = styled(Flex)`
  width: 300px;
  min-width: 300px;
  margin-top: auto;
`;

const SettingSwitch = styled(Flex)`
  padding: 10 0px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.strokeGrey};
`;

const Footer = styled(Flex)`
  width: 100%;
  height: 48px;
  margin-top: 24px;
`;

const Block = styled(Flex)`
  padding: 20px 0 20px 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colors.strokeGrey};
`;

const EmptyListItems = styled.span`
  margin: 5px 0;
  color: ${({ theme }) => theme.colors.textWarning};
`;

const NoActionRequired = styled.span`
  margin: 5px 0;
`;

const ExtraText = styled(Typography.Paragraph)`
  &.ui-typography {
    color: ${({ theme }) => theme.colors.textBlue400};
    font-size: 12px;
  }
`;

type PersonDto = Schemas.PersonDto;
type Person = Schemas.Person;

const FIRST_EDITABLE_COLUMN_INDEX = 2;

export type ModalType = 'manager' | 'ubo' | 'edit';

type PersonTreeEntityType = (PersonDto | Person) & {
  id: string;
  people?: PersonTreeEntityType[];
};

export const OnboardingAddManagersAndUbos = () => {
  const [update, loading] = useUpdateIncorporation();

  const { companyId } = useAccount();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  const [form] = Form.useForm();

  const modalTitle = (type: ModalType) => {
    switch (type) {
      case 'manager':
        return `${t('onboarding.shareholders.newManager')()}${itemToAppend?.name}`;
      case 'ubo':
        return `${t('onboarding.shareholders.newUbo')()}${itemToAppend?.name}`;
      case 'edit':
        return `${t('onboarding.shareholders.editEntity')()}${selectedItem?.name}`;
      default:
        return '';
    }
  };

  const [modalType, setModalType] = useState<ModalType>('manager');

  const okModalButtonText =
    modalType === 'edit'
      ? t('onboarding.addManagersAndUbos.edit')()
      : t('onboarding.addManagersAndUbos.add')();

  const people = incorporationDetails?.people || [];
  const [columns, setColumns] = useState<PersonTreeEntityType[][]>([
    people,
    people[0]?.people,
    people[0]?.people[0]?.people || [],
  ]);
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(
    isAnyLegalPersonWithoutChildren(people) || !hasDirectorAndAuthorized(people)
  );

  const [currentPath, setCurrentPath] = useState<string[]>(
    [people[0], people[0]?.people[0]].map((item) => item.id)
  );

  const [isAddPersonModalVisible, setIsAddPeronModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PersonTreeEntityType | null>(
    null
  );
  const [itemToAppend, setItemToAppend] = useState<PersonTreeEntityType | null>(
    null
  );

  const handleSelectListItem = (item: PersonTreeEntityType, index: number) => {
    if (currentPath.includes(item.id)) {
      setCurrentPath(currentPath.slice(0, index));
      setColumns(columns.slice(0, index + 1));
    } else {
      if (item.isShareholder) {
        form.setFieldsValue({
          isDirector: item.isDirector,
          isSigner: item.isSigner,
        });
      }
      setCurrentPath(currentPath.slice(0, index).concat([item.id]));
      const newColumn = item.people || [];
      setColumns(columns.slice(0, index + 1).concat([newColumn]));
    }
  };

  const onModalSubmit = (data: PersonTreeEntityType) => {
    setIsAddPeronModalVisible(false);

    if (modalType === 'edit') {
      if (selectedItem) {
        selectedItem.name = data.name;
      }
    } else {
      if (itemToAppend) {
        itemToAppend.people = itemToAppend.people || [];
        itemToAppend.people.push(data);
      }
    }

    updatePeopleAndColumns(people);
    setItemToAppend(null);
    setSelectedItem(null);
  };

  const onModalCancel = () => {
    setIsAddPeronModalVisible(false);
    setItemToAppend(null);
    setSelectedItem(null);
  };

  const handleEditListItem = (item: PersonTreeEntityType) => {
    setModalType('edit');
    setIsAddPeronModalVisible(true);
    setSelectedItem(item);
  };

  const handleDeleteItem = (item: PersonTreeEntityType) => {
    if (currentPath.includes(item.id)) {
      setCurrentPath(currentPath.slice(0, currentPath.indexOf(item.id)));
      setColumns(columns.slice(0, currentPath.indexOf(item.id) + 1));
    }

    const newPeople = deleteItemfromPeople(item, people);
    updatePeopleAndColumns(newPeople);
  };

  const updatePeopleAndColumns = (people: PersonTreeEntityType[]) => {
    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: people,
        },
      },
      {
        onSuccess: (response) => {
          const newPeople = response.people;
          updateIncorporation({ people: newPeople });
          const newColumns = currentPath.reduce(
            (acc, id) => {
              const columnToSearch = acc.columns[acc.columns.length - 1];
              const item = columnToSearch.find((person) => person.id === id);
              return {
                columns: acc.columns.concat([item?.people || []]),
                item: item,
              };
            },
            {
              columns: [newPeople],
              item: newPeople.find((person) => person.id === currentPath[0]),
            }
          );
          setColumns(newColumns.columns);
          setIsNextStepDisabled(
            isAnyLegalPersonWithoutChildren(newPeople) ||
              !hasDirectorAndAuthorized(newPeople)
          );
        },
      }
    );
  };

  const deleteItemfromPeople = (
    item: PersonTreeEntityType,
    people: PersonTreeEntityType[]
  ): PersonTreeEntityType[] => {
    if (people.map((el) => el.id).includes(item.id)) {
      return people.filter((el) => el.id !== item.id);
    } else {
      return people.map((el) => {
        if (el.people) {
          return {
            ...el,
            people: deleteItemfromPeople(item, el.people),
          } as PersonTreeEntityType;
        } else {
          return el;
        }
      });
    }
  };

  const handleSubmit = () => {
    if (isNextStepDisabled) return;

    updateScreen('MESSAGE_PROCESS_ONLINE_KYC', { active: true });
  };

  const itemInPath = (index: number) => {
    const itemsInColumn = columns[index - 1];
    const indexOfItemToAppend = itemsInColumn.findIndex(
      (item) => item.id === currentPath[index - 1]
    );
    if (indexOfItemToAppend === -1) return null;

    return itemsInColumn[indexOfItemToAppend];
  };

  const handleOpenAddManagerOrUboModal = (type: ModalType, index: number) => {
    setModalType(type);
    setIsAddPeronModalVisible(true);

    setItemToAppend(itemInPath(index));
  };

  const updateShareholder = () => {
    const item = itemInPath(FIRST_EDITABLE_COLUMN_INDEX);
    if (item) {
      item.isDirector = form.getFieldValue('isDirector');
      item.isSigner = form.getFieldValue('isSigner');
      updatePeopleAndColumns(people);
    }
  };

  return (
    <Flex vertical>
      <OnboardingCardHeader
        title={t(`onboarding.addManagersAndUbos.title`)()}
        description={
          <Row align="bottom" gutter={[20, 0]}>
            <Col span={15}>
              <Paragraph>
                <Trans
                  i18nKey={t(`onboarding.addManagersAndUbos.description`)()}
                  components={[<strong />]}
                />
              </Paragraph>
              <Paragraph>
                <Trans
                  i18nKey={t(
                    `onboarding.addManagersAndUbos.additionalDescription`
                  )()}
                  components={[<Paragraph />]}
                />
              </Paragraph>
            </Col>

            <Col span={9}>
              <Flex gap={8} vertical>
                <ExtraText type="secondary">
                  <Trans
                    i18nKey={t(
                      'onboarding.addManagers.extraTextManagerAndAuthorized'
                    )()}
                    components={[<br />]}
                  />
                </ExtraText>

                <ExtraText type="secondary">
                  <Trans
                    i18nKey={t(
                      'onboarding.addManagers.extraTextManagerAndAuthorizedRequirements'
                    )()}
                    components={[<strong />]}
                  />
                </ExtraText>
              </Flex>
            </Col>
          </Row>
        }
      />

      <Container gap={8}>
        {columns.map((column, index) => (
          <Block key={index}>
            <ItemsContainer gap={8} vertical>
              {column.length ? (
                column.map((item, idx) => (
                  <ListItem
                    key={idx}
                    selected={currentPath.includes(item.id)}
                    item={item}
                    areActionsEnabled={index >= FIRST_EDITABLE_COLUMN_INDEX}
                    onClick={() => handleSelectListItem(item, index)}
                    onEdit={() => handleEditListItem(item)}
                    onDelete={() => handleDeleteItem(item)}
                  />
                ))
              ) : (
                <>
                  {itemInPath(index)?.type === 'legal' ? (
                    <EmptyListItems>
                      {t('onboarding.addManagersAndUbos.emptyList')()}
                    </EmptyListItems>
                  ) : null}
                  {index > FIRST_EDITABLE_COLUMN_INDEX &&
                  itemInPath(index)?.type === 'natural' ? (
                    <NoActionRequired>
                      {t('onboarding.addManagersAndUbos.noActionRequired')()}
                    </NoActionRequired>
                  ) : null}
                </>
              )}
            </ItemsContainer>

            {index === FIRST_EDITABLE_COLUMN_INDEX ? (
              <SettingsContainer vertical gap={10}>
                {itemInPath(index)?.type === 'legal' ? <Divider /> : null}
                <Form
                  form={form}
                  initialValues={{
                    isDirector: people[0]?.people[0].isDirector,
                    isSigner: people[0]?.people[0].isSigner,
                  }}
                >
                  <SettingSwitch gap={12}>
                    <Form.Item name="isDirector" valuePropName="checked">
                      <Switch onChange={updateShareholder} />
                    </Form.Item>
                    Manager role
                  </SettingSwitch>

                  <Divider />

                  <SettingSwitch gap={12}>
                    <Form.Item name="isSigner" valuePropName="checked">
                      <Switch onChange={updateShareholder} />
                    </Form.Item>
                    Authorised person
                  </SettingSwitch>
                </Form>
              </SettingsContainer>
            ) : null}

            {index >= FIRST_EDITABLE_COLUMN_INDEX - 1 &&
            itemInPath(index)?.type === 'legal' ? (
              <Actions gap={20} align="center">
                <ActionButton
                  type="link"
                  onClick={() =>
                    handleOpenAddManagerOrUboModal('manager', index)
                  }
                >
                  {t('onboarding.addManagersAndUbos.addManager')()}
                </ActionButton>
                {index >= FIRST_EDITABLE_COLUMN_INDEX ? (
                  <ActionButton
                    type="link"
                    onClick={() => handleOpenAddManagerOrUboModal('ubo', index)}
                  >
                    {t('onboarding.addManagersAndUbos.addUbo')()}
                  </ActionButton>
                ) : null}
              </Actions>
            ) : null}
          </Block>
        ))}

        <AddShareholderOrUboModal
          open={isAddPersonModalVisible}
          onCancel={onModalCancel}
          entityType={modalType}
          isDisabled={modalType === 'ubo'}
          title={modalTitle(modalType)}
          width={572}
          onSubmit={onModalSubmit}
          okText={okModalButtonText}
          initialType={selectedItem?.type || 'natural'}
          initialName={selectedItem?.name}
        />
      </Container>

      <Footer justify="flex-end" align="center">
        <Button
          type="link"
          onClick={() => updateScreen('SHAREHOLDERS', { active: true })}
        >
          {t('onboarding.buttonGoBack')()}
        </Button>
        <Button
          type="primary"
          onClick={() => handleSubmit()}
          disabled={isNextStepDisabled}
          loading={loading}
        >
          {t('onboarding.buttonNext')()}
        </Button>
      </Footer>
    </Flex>
  );
};
