import { Spin } from 'antd';
import { useMemo } from 'react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { AccessDenied } from '@entities/errorBoundary/AccessDenied';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';
import { useAccount } from '@hooks';
import { LayoutFullPage } from '@layouts/LayoutFullPage';
import { LayoutOnboarding } from '@layouts/LayoutOnboarding';
import { LayoutProtected } from '@layouts/LayoutProtected';
import {
  AcceptInvitation,
  Accounts,
  Categories,
  ConfirmEmail,
  ConnectBank,
  Contacts,
  DocumentDetails,
  Documents,
  Expenses,
  ForgotPassword,
  ForgotPasswordMessage,
  Income,
  InvoiceGenerator,
  Legal,
  Login,
  NotFound,
  Onboarding,
  PrivacyPolicy,
  ProjectCreate,
  ProjectDetails,
  Projects,
  Report,
  ResetPassword,
  Settings,
  SettingsBilling,
  SettingsCompany,
  SettingsPersonal,
  SettingsTeamMembers,
  Taxes,
  TaxesDetails,
  TransactionDetails,
  Transactions,
  TransactionsImport,
} from '@pages';
import { Rules } from '@pages/Rules/Rules';
import { Tasks } from '@pages/Tasks/Tasks';
import { featureFlag, filteredRoutes } from '@utils';

const isFeatureInvoiceGeneratorActive = featureFlag('INVOICE_GENERATOR');

function DefaultErrorComponent({ fullPage }: { fullPage?: boolean }) {
  const error = useRouteError();
  const navigate = useNavigate();
  const stack = error instanceof Error ? error.stack : undefined;

  return (
    <ErrorFallback
      error={error}
      componentStack={stack}
      fullPage={fullPage}
      onClick={() => navigate(-1)}
    />
  );
}

const createRoutes = (
  isLogged: boolean,
  userAccess: Schemas.AccessRights | null
) => {
  const appRoutes: RouteObject[] = [
    {
      path: '/',
      element: <LayoutProtected />,
      children: [
        {
          path: '/accounts',
          element: <Accounts />,
        },
        {
          path: '/documents',
          element: <Documents />,
        },
        {
          path: '/documents/:id',
          element: <DocumentDetails />,
        },
        {
          path: '/transactions',
          element: <Transactions />,
        },
        {
          path: '/projects',
          element: <Projects />,
        },
        {
          path: '/projects/create',
          element: <ProjectCreate />,
        },
        {
          path: '/projects/:id',
          element: <ProjectDetails />,
        },
        {
          path: '/contacts',
          element: <Contacts />,
        },
        {
          path: '/contacts/:id',
          element: <Contacts />,
        },
        {
          path: '/report',
          element: <Report />,
        },
        {
          path: '/taxes',
          element: <Taxes />,
        },
        {
          path: '/taxes/:id',
          element: <TaxesDetails />,
        },
        {
          path: '/categories',
          element: <Categories />,
        },
        {
          path: '/settings',
          element: <Settings />,
        },
        {
          path: '/settings/personal',
          element: <SettingsPersonal />,
        },
        {
          path: '/settings/company',
          element: <SettingsCompany />,
        },
        {
          path: '/settings/billing',
          element: <SettingsBilling />,
        },
        {
          path: '/settings/team-members',
          element: <SettingsTeamMembers />,
        },
        {
          path: '/legal',
          element: <Legal />,
        },
        {
          path: '/income',
          element: <Income />,
        },
        isFeatureInvoiceGeneratorActive
          ? {
              path: '/income/new/:id',
              element: <InvoiceGenerator />,
            }
          : {},
        {
          path: '/income/:id',
          element: <Income />,
        },
        {
          path: '/expenses',
          element: <Expenses />,
        },
        {
          path: '/expenses/:id',
          element: <Expenses />,
        },
        {
          path: '/chat',
          element: <Tasks />,
        },
        {
          path: '/tasks/:id',
          element: <Tasks />,
        },
        {
          path: '/rules',
          element: <Rules />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutProtected noContentPadding />,
      children: [
        {
          path: '/transactions/:id',
          element: <TransactionDetails />,
        },
        {
          path: '/transactions-import/:id',
          element: <TransactionsImport />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutFullPage />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/forgot-password/message',
          element: <ForgotPasswordMessage />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/accept-invitation',
          element: <AcceptInvitation />,
        },
        {
          path: '/privacy-policy',
          element: <PrivacyPolicy />,
        },
        {
          path: '/confirm-email',
          element: <ConfirmEmail />,
        },
        isLogged
          ? {
              path: '/connect-bank',
              element: <ConnectBank />,
            }
          : {},
      ],
    },
    {
      path: '/',
      element: <LayoutOnboarding />,
      children: [
        {
          path: '/start',
          element: <Onboarding />,
        },
      ],
    },
    isLogged
      ? {
          path: '*',
          element: <LayoutProtected />,
          children: [
            {
              path: '*',
              element: <NotFound />,
            },
          ],
        }
      : {
          path: '*',
          element: <LayoutFullPage />,
          children: [
            {
              path: '*',
              element: <Login />,
            },
          ],
        },
  ];

  appRoutes.forEach((route) => {
    route.children?.forEach((child) => {
      child.errorElement = <DefaultErrorComponent />;
    });
  });

  const removePaths: Record<string, string[]> = {
    transactions: ['transactions'],
    companyData: ['legal'],
    userManagement: ['team-members'],
    reports: ['report'],
    payroll: ['payroll'],
    incomesExpences: ['expences', 'invoicing'],
  };

  const routes = filteredRoutes(
    userAccess,
    appRoutes,
    removePaths,
    <AccessDenied />
  );

  return [
    {
      path: '/',
      errorElement: <DefaultErrorComponent />,
      children: routes,
    },
  ];
};

const AppRouter = () => {
  const { isLogged, userAccess } = useAccount();

  const routes = useMemo(
    () => createRoutes(isLogged, userAccess),
    [isLogged, userAccess]
  );

  const router = createBrowserRouter(routes, {
    basename: import.meta.env.VITE_ROUTE_BASE_URL || '/',
  });

  return <RouterProvider router={router} fallbackElement={<Spin />} />;
};

export default AppRouter;
