import { Button, Flex, Spin, Typography, Upload } from 'antd';
import { FC, Fragment, PropsWithChildren, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import {
  IconRefresh,
  IconTrash,
  IconUpload,
  IconWarningTransparent,
} from '@assets';
import OnboardingViewDocumentModal from '@entities/onboarding/OnboardingViewDocumentModal';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import {
  useCreateCompanyFile,
  useDeleteCompanyFile,
  useUpdateCompanyFileById,
} from '@hooks-api';

import * as S from './styled';

type KYCFilesProps = {
  person: Pick<Schemas.Person, 'files' | 'id'>;
  selectedType: string | null;
  selectedStepValue: string;
};

type CompanyFile = Schemas.CompanyFile;

const { Paragraph } = Typography;

const KYCFiles: FC<PropsWithChildren<KYCFilesProps>> = ({
  person,
  selectedType,
  selectedStepValue,
}) => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [selectedViewFile, setSelectedViewFile] = useState<CompanyFile | null>(
    null
  );

  const details = incorporationDetails as Schemas.Incorporation;

  const [createCompanyFile, loadingCreate] = useCreateCompanyFile();
  const [updateCompanyFile, loadingUpdate] = useUpdateCompanyFileById();
  const [deleteCompanyFile] = useDeleteCompanyFile();

  const handleUploadFile = (options: any) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('name', options.file.name);
    formData.append('type', selectedStepValue);

    if (person) {
      formData.append('personId', person.id);
    }

    createCompanyFile(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => handleUpdatePerson(response, 'create'),
      }
    );
  };

  const handleUpdate = (options: any, id: string) => {
    const formData = new FormData();

    formData.append('file', options.file);
    formData.append('name', options.file.name);

    updateCompanyFile(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => handleUpdatePerson(response, 'update'),
      }
    );
  };

  const handleUpdatePerson = (file: CompanyFile, type: 'create' | 'update') => {
    const files = person.files[selectedStepValue];

    const newFiles = {
      ...person.files,
      [selectedStepValue]:
        type === 'create'
          ? [...(files || []), file]
          : files.map((item: CompanyFile) => {
              if (item.id === file.id) {
                return file;
              }

              return item;
            }),
    };

    person.files = newFiles;

    updateIncorporation({
      people: details?.people.map((personItem) =>
        personItem.id === person.id ? person : personItem
      ) as Schemas.Person[],
    });
  };

  const handleRemove = (id: string) => {
    deleteCompanyFile(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
      },
      {
        onSuccess: () => {
          const files = person.files[selectedStepValue];

          const newFiles = {
            ...person.files,
            [selectedStepValue]: files.filter(
              (item: CompanyFile) => item.id !== id
            ),
          };
          person.files = newFiles;

          updateIncorporation({
            people: details?.people.map((personItem) =>
              personItem.id === person.id ? person : personItem
            ) as Schemas.Person[],
          });
        },
      }
    );
  };

  return (
    <Flex gap={20} vertical>
      <OnboardingViewDocumentModal
        open={isOpenViewModal}
        type={selectedType}
        step={selectedStepValue}
        file={selectedViewFile}
        onCancel={() => {
          setIsOpenViewModal(false);
          setSelectedViewFile(null);
        }}
      />

      {person.files[selectedStepValue]?.length > 0 ? (
        <Flex gap={12} vertical>
          <S.FileList>
            {person.files[selectedStepValue].map((file: CompanyFile) => (
              <Fragment key={file.id}>
                {loadingUpdate ? (
                  <S.Uploading>
                    <Spin indicator={<LoadingOutlined spin />} />
                    {translate('onboarding.kyc.uploading')}
                  </S.Uploading>
                ) : (
                  <>
                    <S.FileItem>
                      <S.FileName
                        onClick={() => {
                          setIsOpenViewModal(true);
                          setSelectedViewFile(file);
                        }}
                      >
                        {file.hasError && (
                          <S.StepNumber status="errorsFound">
                            <IconWarningTransparent />
                          </S.StepNumber>
                        )}

                        <S.FileNameOverflow>
                          {file.fileName || file.id}
                        </S.FileNameOverflow>
                      </S.FileName>

                      <Flex align="center" gap={8}>
                        <Upload
                          customRequest={(opts) => handleUpdate(opts, file.id)}
                          showUploadList={false}
                        >
                          <Button
                            type="text"
                            icon={<IconRefresh />}
                            size="small"
                          />
                        </Upload>

                        {!file.hasError && (
                          <Button
                            type="text"
                            icon={<IconTrash />}
                            size="small"
                            onClick={() => handleRemove(file.id)}
                          />
                        )}
                      </Flex>
                    </S.FileItem>

                    {file.hasError && (
                      <S.FileItemError>
                        {tDynamic(`onboarding.kyc.errors.${file.errorCode}`)}
                      </S.FileItemError>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </S.FileList>

          <Upload customRequest={handleUploadFile} showUploadList={false}>
            <S.UploadMore>
              {translate('onboarding.kyc.uploadMore')}
            </S.UploadMore>
          </Upload>
        </Flex>
      ) : loadingCreate ? (
        <S.Uploading>
          <Spin indicator={<LoadingOutlined spin />} />
          {translate('onboarding.kyc.uploading')}
        </S.Uploading>
      ) : (
        <S.Dragger
          multiple={false}
          customRequest={handleUploadFile}
          showUploadList={false}
        >
          <Flex gap={16} align="center" vertical>
            <Flex align="center" gap={6} vertical>
              <IconUpload />

              <Paragraph type="secondary">
                {translate('onboarding.kyc.uploadControl.drop')}
              </Paragraph>
            </Flex>

            <Button size="small">
              {translate('onboarding.kyc.uploadControl.selectFile')}
            </Button>
          </Flex>
        </S.Dragger>
      )}
    </Flex>
  );
};

export default KYCFiles;
