import { Flex } from 'antd';

import styled from '@emotion/styled';

export const EmptyTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const EmptyDescription = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const EmptyContainer = styled(Flex)`
  padding: 56px 0 16px;
  margin-top: 42px;
`;

export const Icon = styled(Flex)`
  width: 120px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.text200};
  border-radius: 120px;
  color: ${({ theme }) => theme.colors.white};
`;
