import { Flex, Grid } from 'antd';
import { FC } from 'react';

import {
  OnboardingAbout,
  OnboardingCard,
  OnboardingCompanyName,
  OnboardingCompanyType,
  OnboardingCompanyTypeAccounting,
  OnboardingCreateAccount,
  OnboardingDeclarationOfHonor,
  OnboardingDescribe,
  OnboardingLegalAddress,
  OnboardingMessageAccountOpened,
  OnboardingMessageCompletedAccounting,
  OnboardingMessageCompletedCompany,
  OnboardingMessageCompletedDocuments,
  OnboardingMessageErrorDocuments,
  OnboardingMessageProcessDocuments,
  OnboardingMessageProcessOnline,
  OnboardingMessageProcessPaymentRcs,
  OnboardingMessageProcessPaymentSent,
  OnboardingMessageProcessVerifyDocuments,
  OnboardingMessageSarlSRcs,
  OnboardingOverview,
  OnboardingPayment,
  OnboardingPaymentDetails,
  OnboardingProvideAddress,
  OnboardingReviewServices,
  OnboardingShareholders,
  OnboardingShareholdersInvite,
  OnboardingSteps,
  OnboardingTransferShare,
  OnboardingUploadDocuments,
  OnboardingVerifyAccount,
  OnboardingWelcome,
  OnboardingWelcomeLanguage,
} from '@entities';
import { OnboardingAddManagersAndUbos } from '@entities/onboarding/OnboardingAddManagersAndUbos/OnboardingAddManagersAndUbos';
import { useOnboarding } from '@hooks';

import * as S from './styled';

const { useBreakpoint } = Grid;

const Onboarding: FC = () => {
  const breakpoint = useBreakpoint();

  const {
    incorporationDetails,
    steps,
    screens,
    currentScreenName,
    isIncorporation,
  } = useOnboarding();

  const isVisibleSteps = screens[currentScreenName]?.visibleSteps;

  const isAutoHeightCardForMobile =
    (screens.WELCOME_LANGUAGE.active ||
      screens.WELCOME.active ||
      screens.COMPANY_NAME.active) &&
    !breakpoint.md;

  return (
    <S.Container>
      <S.Inner withPadding={steps.COMPANY_NAME.active}>
        {steps.COMPANY_NAME.active && (
          <S.Content
            width={screens.VERIFY_ACCOUNT.active ? 645 : 791}
            centering
          >
            <OnboardingCard
              isAutoHeight={
                screens.VERIFY_ACCOUNT.active || isAutoHeightCardForMobile
              }
            >
              {isVisibleSteps && (
                <OnboardingSteps
                  title={`1. ${translateUntyped(`onboarding.steps.companyName.name_${incorporationDetails?.incorporationType}`)()}`}
                  name={t(
                    // @ts-expect-error-next-line
                    `onboarding.steps.companyName.${currentScreenName}`
                  )()}
                  divisionsCount={3}
                />
              )}

              {screens.WELCOME_LANGUAGE.active && <OnboardingWelcomeLanguage />}

              {screens.WELCOME.active && <OnboardingWelcome />}

              {screens.INCORPORATION.active && <OnboardingOverview />}

              {screens.COMPANY_NAME.active && <OnboardingCompanyName />}

              {screens.DESCRIBE.active && <OnboardingDescribe />}

              {screens.CREATE_ACCOUNT.active && <OnboardingCreateAccount />}

              {screens.VERIFY_ACCOUNT.active && <OnboardingVerifyAccount />}

              {screens.MESSAGE_COMPLETED_COMPANY.active && (
                <OnboardingMessageCompletedCompany />
              )}
            </OnboardingCard>
          </S.Content>
        )}

        {steps.SHAREHOLDERS.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={screens.UBOS.active ? '100%' : 823}>
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`2. ${translateUntyped(`onboarding.steps.shareholders.name_${incorporationDetails?.incorporationType}`)()}`}
                    name={t(
                      // @ts-expect-error-next-line
                      `onboarding.steps.shareholders.${currentScreenName}`
                    )()}
                    divisionsCount={3}
                  />
                )}

                {screens.INCORPORATION.active && <OnboardingOverview />}

                {screens.COMPANY_TYPE.active && <OnboardingCompanyType />}

                {screens.ACCOUNTING_PLAN.active && (
                  <OnboardingCompanyTypeAccounting />
                )}

                {screens.SHAREHOLDERS.active && <OnboardingShareholders />}

                {screens.UBOS.active && <OnboardingAddManagersAndUbos />}

                {screens.MESSAGE_PROCESS_ONLINE_KYC.active && (
                  <OnboardingMessageProcessOnline />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && isIncorporation && (
              <OnboardingAbout />
            )}
          </Flex>
        )}

        {steps.ONLINE_KYC.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content
              width={
                screens.UPLOAD_DOCUMENTS.active ||
                screens.REVIEW_DOCUMENTS.active
                  ? '100%'
                  : 823
              }
            >
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`3. ${t('onboarding.steps.onlineKyc.name')()}`}
                    name={t(
                      // @ts-expect-error-next-line
                      `onboarding.steps.onlineKyc.${currentScreenName}`
                    )()}
                    divisionsCount={4}
                  />
                )}

                {screens.INCORPORATION.active && <OnboardingOverview />}

                {(screens.UPLOAD_DOCUMENTS.active ||
                  screens.REVIEW_DOCUMENTS.active) && (
                  <OnboardingUploadDocuments />
                )}

                {screens.LEGAL_ADDRESS.active && <OnboardingLegalAddress />}

                {screens.PROVIDE_ADDRESS.active && <OnboardingProvideAddress />}

                {screens.MESSAGE_PROCESS_DOCUMENTS.active && (
                  <OnboardingMessageProcessDocuments />
                )}

                {screens.MESSAGE_ERROR_DOCUMENTS.active && (
                  <OnboardingMessageErrorDocuments />
                )}

                {screens.MESSAGE_COMPLETED_DOCUMENTS.active && (
                  <OnboardingMessageCompletedDocuments />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && isIncorporation && (
              <OnboardingAbout />
            )}
          </Flex>
        )}

        {steps.VERIFY_DOCUMENTS.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`4. ${t('onboarding.steps.verifyDocuments.name')()}`}
                    name={t(
                      // @ts-expect-error-next-line
                      `onboarding.steps.verifyDocuments.${currentScreenName}`
                    )()}
                    divisionsCount={2}
                  />
                )}

                {screens.INCORPORATION.active && <OnboardingOverview />}

                {screens.INVITE_SHAREHOLDERS_MANAGERS.active && (
                  <OnboardingShareholdersInvite />
                )}

                {screens.REVIEW_SERVICES.active && <OnboardingReviewServices />}

                {screens.MESSAGE_PROCESS_VERIFY_DOCUMENTS.active && (
                  <OnboardingMessageProcessVerifyDocuments />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && isIncorporation && (
              <OnboardingAbout />
            )}
          </Flex>
        )}

        {steps.BANK_TRANSFERRING.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {isVisibleSteps && (
                  <OnboardingSteps
                    title={`5. ${t('onboarding.steps.bankTransferring.name')()}`}
                    name={t(
                      // @ts-expect-error-next-line
                      `onboarding.steps.bankTransferring.${currentScreenName}`
                    )()}
                    divisionsCount={2}
                  />
                )}

                {screens.COMPLETED_ACCOUNTING.active && (
                  <OnboardingMessageCompletedAccounting />
                )}
                {screens.INCORPORATION.active && <OnboardingOverview />}

                {screens.PAYMENT.active && <OnboardingPayment />}

                {screens.PAYMENT_DETAILS.active && <OnboardingPaymentDetails />}

                {screens.MESSAGE_PROCESS_ACCOUNT_OPENED.active && (
                  <OnboardingMessageAccountOpened />
                )}

                {screens.MESSAGE_PROCESS_PAYMENT_SENT.active && (
                  <OnboardingMessageProcessPaymentSent />
                )}

                {screens.TRANSFER_SHARE.active && <OnboardingTransferShare />}

                {screens.MESSAGE_PROCESS_PAYMENT_TO_RCS.active && (
                  <OnboardingMessageProcessPaymentRcs />
                )}

                {screens.HONOR_DECLARATION.active && (
                  <OnboardingDeclarationOfHonor />
                )}

                {screens.MESSAGE_PROCESS_SARL_S_RCS.active && (
                  <OnboardingMessageSarlSRcs />
                )}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && <OnboardingAbout />}
          </Flex>
        )}

        {steps.REGISTRATION_IN_RCS.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {screens.INCORPORATION.active && <OnboardingOverview />}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && <OnboardingAbout />}
          </Flex>
        )}

        {steps.INCORPORATED.active && (
          <Flex align="flex-start" gap={40}>
            <S.Content width={823}>
              <OnboardingCard>
                {screens.INCORPORATION.active && <OnboardingOverview />}
              </OnboardingCard>
            </S.Content>

            {screens.INCORPORATION.active && <OnboardingAbout />}
          </Flex>
        )}
      </S.Inner>
    </S.Container>
  );
};

export default Onboarding;
