import {
  Alert,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from 'antd';
import { FC, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';

import { PlusOutlined } from '@ant-design/icons';
import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { KYCFiles } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

type KYCWealthProps = {
  person: {
    kycData?: Pick<
      (Schemas.Person)['kycData'],
      'incomes' | 'wealthErrorCode'
    >;
    files: (Schemas.Person)['files'];
    id: (Schemas.Person)['id'];
  };
};

const { Paragraph, Title } = Typography;

// TODO fix indirect prop modification
const KYCWealth: FC<PropsWithChildren<KYCWealthProps>> = ({ person }) => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const [form] = Form.useForm();

  const errorCode = person.kycData?.wealthErrorCode;

  if (person.kycData) {
    if (!person.kycData.incomes || person.kycData.incomes.length === 0) {
      person.kycData.incomes = [
        { type: 'proffesional', sumAndDescription: '' },
      ];
    }
  } else {
    person.kycData = {
      incomes: [{ type: 'proffesional', sumAndDescription: '' }],
    };
  }

  const [update] = useUpdateIncorporation();

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
      wealthErrorCode: null,
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.natural.wealth')}</Title>

        <Paragraph type="secondary">
          <Trans
            i18nKey={translate('onboarding.kyc.naturalDetails.wealth')}
            components={[<br />, <strong />]}
          />
        </Paragraph>

        {errorCode && (
          <Alert
            type="error"
            message={tDynamic(`onboarding.kyc.errors.${errorCode}`)}
          />
        )}
      </div>

      <Form
        form={form}
        layout="vertical"
        colon={false}
        initialValues={person.kycData}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <S.FormItem
              name="totalWealth"
              label={translate(
                'onboarding.kyc.naturalKycForm.totalWealth.label'
              )}
            >
              <Input
                size="large"
                placeholder={translate(
                  'onboarding.kyc.naturalKycForm.totalWealth.placeholder'
                )}
                onBlur={onFinish}
              />
            </S.FormItem>
          </Col>
        </Row>

        <Form.List name="incomes">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name, key, ...field }) => (
                <Flex vertical key={key}>
                  <Row gutter={[24, 0]}>
                    <S.IncomeCol span={20}>
                      <S.FormItem
                        {...field}
                        label={translate(
                          'onboarding.kyc.naturalKycForm.incomeType.label'
                        )}
                        name={[name, 'type']}
                      >
                        <Select
                          placeholder={translate(
                            'onboarding.kyc.naturalKycForm.incomeType.placeholder'
                          )}
                          options={[
                            {
                              label: translate(
                                'onboarding.kyc.naturalKycForm.incomeType.values.professional'
                              ),
                              value: 'proffesional',
                            },
                            {
                              label: translate(
                                'onboarding.kyc.naturalKycForm.incomeType.values.rental'
                              ),
                              value: 'rental',
                            },
                            {
                              label: translate(
                                'onboarding.kyc.naturalKycForm.incomeType.values.savings'
                              ),
                              value: 'savings',
                            },
                            {
                              label: translate(
                                'onboarding.kyc.naturalKycForm.incomeType.values.propertySale'
                              ),
                              value: 'propertySale',
                            },
                            {
                              label: translate(
                                'onboarding.kyc.naturalKycForm.incomeType.values.donation'
                              ),
                              value: 'donation',
                            },
                            {
                              label: translate(
                                'onboarding.kyc.naturalKycForm.incomeType.values.other'
                              ),
                              value: 'other',
                            },
                          ]}
                          size="large"
                          onChange={onFinish}
                          virtual
                        />
                      </S.FormItem>
                      <S.FormItem
                        {...field}
                        label={translate(
                          'onboarding.kyc.naturalKycForm.sumAndDescription.label'
                        )}
                        name={[name, 'sumAndDescription']}
                      >
                        <Input
                          size="large"
                          placeholder={translate(
                            'onboarding.kyc.naturalKycForm.sumAndDescription.placeholder'
                          )}
                          onBlur={onFinish}
                        />
                      </S.FormItem>

                      <div>
                        <h4>
                          {translate(
                            'onboarding.kyc.naturalKycForm.supportingDocuments'
                          )}
                        </h4>
                        <KYCFiles
                          person={person}
                          selectedType="natural"
                          selectedStepValue={`wealth_${person.kycData?.incomes?.[key]?.type}`}
                        />
                      </div>
                    </S.IncomeCol>
                    <S.DeleteCol>
                      <S.ButtonRemove
                        onClick={() => {
                          remove(name);
                          onFinish();
                        }}
                      >
                        <IconTrash />
                      </S.ButtonRemove>
                    </S.DeleteCol>
                  </Row>
                </Flex>
              ))}

              <Flex justify="space-between">
                <Flex align="center" gap={28}>
                  <S.ButtonAdd onClick={() => add()}>
                    <PlusOutlined />
                    {translate('onboarding.kyc.naturalKycForm.incomeType.add')}
                  </S.ButtonAdd>
                </Flex>
              </Flex>
            </>
          )}
        </Form.List>
      </Form>
    </Flex>
  );
};

export default KYCWealth;
