import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconClose, IconDownload, IconEye } from '@assets';
import styled from '@emotion/styled';
import { Button } from '@ui-kit/Button/Button';
import { downloadFile, formatFileSize } from '@utils';

const Wrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 12px;
  padding: 16px;
  margin-top: 4px;
`;

const Header = styled(Flex)`
  width: 100%;
`;

const Inner = styled(Flex)`
  width: 100%;
  flex: 1;
`;

const Name = styled(Flex)`
  width: 220px;
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Size = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;

type FileProps = {
  file: Schemas.TaskFile;
  onView: () => void;
  onRemove?: (id: string) => void;
};

const File: FC<FileProps> = ({ file, onView, onRemove }) => {
  const isTypeImage = file.mimeType.includes('image');
  const isTypePDF = file.mimeType.includes('pdf');

  return (
    <Wrapper align="center" justify="space-between" gap={12}>
      <Header gap={12}>
        <Inner gap={4} vertical>
          <Name gap={12}>{file.fileName}</Name>

          {!!file.fileSize && <Size>{formatFileSize(file.fileSize)}</Size>}
        </Inner>
      </Header>

      <Flex align="center" gap={8}>
        {(isTypePDF || isTypeImage) && (
          <Button
            type="link"
            size="small"
            icon={<IconEye />}
            onClick={onView}
          />
        )}

        <Button
          type="link"
          size="small"
          icon={<IconDownload />}
          onClick={() => downloadFile(file.url, file.fileName, true)}
        />

        {onRemove && (
          <Button
            type="link"
            size="small"
            icon={<IconClose />}
            onClick={() => onRemove(file.id)}
          />
        )}
      </Flex>
    </Wrapper>
  );
};

export default File;
