import { Col, DatePicker, Flex, Form, Input, Row, Table, message } from 'antd';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { Card, CopyButton, Loader } from '@components';
import {
  AttachTransactionsModal,
  DetailsHeader,
  EmptyDocumentPreivew,
  EmptyDocumentTransactions,
  FileViewer,
  FilterContacts,
  FilterTypes,
  PageMeta,
  TransactionContentModal,
  UploadFile,
} from '@entities';
import {
  useAccount,
  useDocumentsTransactionsColumns,
  useTranslate,
} from '@hooks';
import {
  useDocumentUpdate,
  useDocumentUpload,
  useGetDocumentById,
} from '@hooks-api';
import { Button } from '@ui-kit/Button/Button';
import { DATE_ISO_FORMAT, getDateDefault, historyReplace } from '@utils';

import * as S from './styled';

const DocumentDetails: FC = () => {
  const [transactions, setTransactions] = useState<Schemas.Transaction[]>([]);
  const [open, setOpen] = useState(false);
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [defaultSelectedTransactions, setDefaultSelectedTransactions] =
    useState<Schemas.Transaction[]>([]);
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [selectedTransactionPage, setSelectedTransactionPage] =
    useState<number>(1);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id: documentId } = useParams();
  const { companyId, userAccess } = useAccount();
  const { translate } = useTranslate();
  const { columns, selectedTransactions, setSelectedTransactions } =
    useDocumentsTransactionsColumns('selected');
  const isCreateScreen = documentId === 'new';

  const {
    data: file,
    loading,
    refetch,
  } = useGetDocumentById({
    params: {
      id: documentId!,
      companyId: companyId!,
    },
    config: {
      enabled: !isCreateScreen,
    },
  });

  const { uploadStatus, uploadDocument } = useDocumentUpload(companyId!);
  const { updateStatus, updateDocument } = useDocumentUpdate(
    companyId!,
    documentId!
  );

  useEffect(() => {
    refetch();
  }, [refetch, transactionModalOpen]);

  useEffect(() => {
    if (updateStatus.response) {
      refetch();
    }
  }, [refetch, updateStatus]);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    file?.transactions && setTransactions(file.transactions);
    setDefaultSelectedTransactions(file?.transactions || []);

    if (file?.url) {
      setFileUrl(file.url);
    }
  }, [file]);

  useEffect(() => {
    if (updateStatus.isLoading) {
      setShowLoader(true);
    }

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 600);

    return () => {
      clearTimeout(timer);
    };
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (
      !isEqual(
        transactions.map((t) => t.id),
        selectedTransactions.map((t) => t.id)
      )
    ) {
      setSelectedTransactions(transactions);
    }
  }, [selectedTransactions, setSelectedTransactions, transactions]);

  const onFinish = useCallback(
    (transactionsArray?: Schemas.Transaction[]) => {
      const values = form.getFieldsValue();
      const { name, type, contactId, notes, issueDate } = values;
      const formData = new FormData();

      fileAttachment && formData.append('file', fileAttachment!);
      name && formData.append('name', name!);
      type && formData.append('type', type!);
      contactId && formData.append('contactId', contactId!);
      issueDate &&
        formData.append(
          'issueDate',
          getDateDefault(issueDate!, DATE_ISO_FORMAT)
        );
      notes && formData.append('notes', notes);
      const transactionsString = () => {
        const string = transactionsArray
          ? transactionsArray.map((t) => t.id).join(',')
          : transactions.map((t) => t.id).join(',');
        if (string !== '') {
          return string;
        }
        return [];
      };

      transactionsString &&
        formData.append('transactionIds', transactionsString().toString());
      if (isCreateScreen) {
        uploadDocument(formData!);
      } else {
        updateDocument(formData!);
      }
    },
    [
      fileAttachment,
      form,
      isCreateScreen,
      transactions,
      updateDocument,
      uploadDocument,
    ]
  );

  useEffect(() => {
    setTransactions(selectedTransactions);

    if (!isEqual(selectedTransactions, defaultSelectedTransactions)) {
      onFinish(selectedTransactions);
    }
  }, [defaultSelectedTransactions, onFinish, selectedTransactions]);

  useEffect(() => {
    if (uploadStatus.response) {
      navigate(`/documents/${uploadStatus.response?.id}`);
    }
  }, [navigate, uploadStatus]);

  useEffect(() => {
    updateStatus.success &&
      message.success(translate('documentsPage.upload.success'));
    updateStatus.error &&
      message.error(translate('documentsPage.upload.error'));
    setDefaultSelectedTransactions(updateStatus.response?.transactions || []);

    if (uploadStatus?.response?.url) {
      setFileUrl(updateStatus?.response?.url || '');
    }
  }, [
    updateStatus.success,
    updateStatus.error,
    updateStatus.response?.transactions,
    updateStatus.response?.url,
    translate,
    uploadStatus?.response?.url,
  ]);

  useEffect(() => {
    if (fileAttachment !== null) {
      onFinish();
    }
  }, [fileAttachment, onFinish]);

  useEffect(() => {
    if (selectedTransactionId) {
      const index = transactions?.findIndex(
        (item) => item.id === selectedTransactionId
      );
      if (index && index !== -1) {
        setSelectedTransactionPage(index + 1);
      }
    }
  }, [transactions, selectedTransactionId, transactionModalOpen]);

  if (loading && !isCreateScreen) {
    return <Loader />;
  }

  const setFile = (file: File | null) => {
    setFileAttachment(file);
  };

  const attachTransactions = (transactions: Schemas.Transaction[]) => {
    setSelectedTransactions(transactions);
  };

  const handleCloseContentModal = () => {
    setTransactionModalOpen(false);
    setSelectedTransactionId(null);
    setSelectedTransactionPage(1);
    historyReplace(`/documents/${documentId}`);
  };

  const handleRedirectToDetails = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/transactions/${id}`, '_blank');
    } else {
      setTransactionModalOpen(true);
    }
  };

  const handleClickRow = (record: Schemas.Transaction) => ({
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = e.target as HTMLElement;
      const deleteButton = target.closest('[data-delete-button]');

      if (deleteButton) {
        e.stopPropagation();
        setSelectedTransactions((prev) =>
          prev.filter((item) => item.id !== record.id)
        );
        return;
      } else {
        handleRedirectToDetails(e, record.id);
        setSelectedTransactionId(record.id);
      }
    },
  });

  const isTypePDF = file?.mimeType?.includes('pdf');

  const AttachTransactionsButton = () => (
    <Button
      type="primary"
      icon={<IconPlus />}
      size="small"
      onClick={() => setOpen(true)}
    >
      {translate('documentsPage.form.attachTransactions')}
    </Button>
  );

  return (
    <Flex gap={24} vertical>
      <PageMeta title={file?.name} />
      <DetailsHeader>
        {isCreateScreen ? null : (
          <CopyButton
            tooltipPlacement="bottom"
            icon="chain"
            value={location.href}
            type="link"
          />
        )}
      </DetailsHeader>
      <TransactionContentModal
        id={selectedTransactionId}
        open={transactionModalOpen && !!selectedTransactionPage}
        transactions={transactions}
        onCancel={handleCloseContentModal}
        page={selectedTransactionPage}
      />
      {showLoader ? (
        <S.LoaderContainer>
          <Loader />
        </S.LoaderContainer>
      ) : null}
      <Flex gap={30} vertical>
        <Form
          layout="vertical"
          onFinish={onFinish}
          colon={false}
          requiredMark={false}
          form={form}
          initialValues={{
            notes: file?.notes ?? '',
            name: file?.name ?? '',
            contactId: file?.contact?.id ?? '',
            type: file?.type ?? '',
            issueDate: file?.issueDate ? dayjs(file.issueDate) : dayjs(),
          }}
        >
          {userAccess?.transactions && (
            <AttachTransactionsModal
              open={open}
              setOpen={setOpen}
              fileTransactions={transactions}
              onAttachTransactions={attachTransactions}
            />
          )}

          <Flex vertical gap={30}>
            <Row gutter={[30, 30]}>
              <Col span={13}>
                <Card title={translate('documentsPage.documentDetails')}>
                  <Row gutter={[30, 30]}>
                    <Col span={24}>
                      <Form.Item
                        name="file"
                        rules={[
                          {
                            required: !fileAttachment && !file?.name,
                          },
                          () => ({
                            validator() {
                              if (fileAttachment || file?.name) {
                                return Promise.resolve();
                              }
                              return Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <UploadFile
                          url={fileUrl!}
                          name={file?.fileName}
                          loading={uploadStatus.isLoading}
                          setFileAttachment={setFile}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[30, 0]}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label={translate('documentsPage.form.documentName')}
                        rules={[{ required: true }]}
                      >
                        <Input
                          size="large"
                          placeholder={translate('documentsPage.enterName')}
                          onBlur={() => onFinish()}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <S.SelectWrapper
                        rules={[{ required: true }]}
                        name="type"
                        label={translate('documentsPage.table.type')}
                      >
                        <FilterTypes
                          placeholder={translate('documentsPage.dropdown.type')}
                          withoutContainer
                          onChange={() => onFinish()}
                        />
                      </S.SelectWrapper>
                    </Col>

                    <Col span={12}>
                      <S.SelectWrapper
                        name="contactId"
                        label={translate('documentsPage.table.contact')}
                      >
                        <FilterContacts
                          onChange={() => onFinish()}
                          placeholder={translate('documentsPage.table.type')}
                          listWidth={300}
                          withoutContainer
                        />
                      </S.SelectWrapper>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        rules={[{ required: true }]}
                        name="issueDate"
                        label={translate('documentsPage.form.issueDate')}
                      >
                        <DatePicker
                          placeholder={translate(
                            'documentsPage.form.issueDate'
                          )}
                          format="DD.MM.YYYY"
                          size="large"
                          onChange={() => onFinish()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="notes"
                    label={translate('common.notes.title')}
                  >
                    <Input.TextArea
                      placeholder={translate('common.notes.placeholder')}
                      rows={7}
                      size="large"
                      onBlur={() => onFinish()}
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={11}>
                <Card
                  title={translate('documentsPage.form.preview')}
                  minHeight={100}
                  minHeightSuffix="%"
                >
                  {!!file && isTypePDF ? (
                    <FileViewer height="507px" selectedFile={file as any} />
                  ) : (
                    <EmptyDocumentPreivew unsupported={!!file && !isTypePDF} />
                  )}
                </Card>
              </Col>
            </Row>

            {userAccess?.transactions && (
              <Row gutter={[48, 30]}>
                <Col span={24}>
                  <Card title={translate('documentsPage.form.transactions')}>
                    {transactions.length ? (
                      <Flex gap={20} align="flex-start" vertical>
                        <S.TableContainer>
                          <Table
                            rowKey={({ id }) => id}
                            onRow={handleClickRow}
                            dataSource={transactions}
                            columns={columns}
                            loading={loading}
                            pagination={false}
                          />
                        </S.TableContainer>
                        <AttachTransactionsButton />
                      </Flex>
                    ) : (
                      <EmptyDocumentTransactions
                        actionButton={<AttachTransactionsButton />}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            )}
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
};

export default DocumentDetails;
